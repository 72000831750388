import {Route, Routes, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import AuthLayout from "../layouts/auth-layout/AuthLayout";
import Login from "../views/auth/signin/Login";
import UnauthorizedRoute from "./UnauthorizedRoute";
import Home from "../views/website/home/Home";
import WebLayout from "../layouts/WebLayout";
import About from "../views/website/about/About";
import Product from "../views/website/product/Product";
import Contact from "../views/website/contact/Contact";
import Prac from "../views/website/about/Prac";
import BioMatric from "../views/website/product/biomatric/BioMatric";
import DigitalPage from "../views/website/product/digital-identity/Digital";
import IAMPage from "../views/website/product/IAM/IAM";
import TochlessPage from "../views/website/product/tochless/Tochless";
import UtrustSurveillance from "../views/website/product/utrust-surveillance/UtrustSurveillance";
import ServicesPage from "../views/website/services/Services";

function RouteConfig() {
    const location = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    return (
        <Routes>
            <Route
                element={
                    <WebLayout>

                    </WebLayout>
                }
            >
                <Route path={'/'} element={<Home/>}></Route>
                <Route path={'/about'} element={<About/>}></Route>
                <Route path={'/product'} element={<Product/>}></Route>
                <Route path={'/contact'} element={<Contact/>}></Route>
                <Route path={'/biomatric-sdks'} element={<BioMatric/>}></Route>
                <Route path={'/digital-identity'} element={<DigitalPage/>}></Route>
                <Route path={'/id-solutions'} element={<IAMPage/>}></Route>
                <Route path={'/tochless-biomatric'} element={<TochlessPage/>}></Route>
                <Route path={'/utrust-surveillance'} element={<UtrustSurveillance/>}></Route>

                <Route path={'/services'} element={<ServicesPage/>}></Route>
            </Route>
            <Route path={'/prac'} element={<Prac/>}></Route>
            {/*<Route*/}
            {/*    path="/account-setup"*/}
            {/*    element={*/}
            {/*        // <UnauthorizedRoute roles={["artist", "fan", "vanue"]}>*/}
            {/*        <AccountLayout/>*/}
            {/*        // </UnauthorizedRoute>*/}

            {/*    }*/}
            {/*/>*/}



            {/*<Route*/}
            {/*    element={*/}
            {/*            <AuthLayout/>*/}
            {/*    }*/}
            {/*>*/}
            {/*    <Route path={'role-selection'} element={<RoleSelection/>}></Route>*/}
            {/*    <Route path={'subscription'} element={<Subscription/>}></Route>*/}
            {/*</Route>*/}

            <Route
                element={
                    <UnauthorizedRoute>
                        <AuthLayout/>
                    </UnauthorizedRoute>
                }
            >
                <Route path="/login" element={<Login />} />

            </Route>

        </Routes>
    )
}

export default RouteConfig