import React, {useRef} from "react"
import {Col, Container, Row} from "react-bootstrap"
import "./home.scss"
import {GoDotFill} from "react-icons/go";
import icon1 from "../../../../assets/website-images/home/about-icon-1.png";
import icon2 from "../../../../assets/website-images/home/about-icon-2.png";
import icon3 from "../../../../assets/website-images/home/about-icon-3.png";
import {FaArrowRight, FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import aboutImg from "../../../../assets/website-images/home/about.png"
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
// import required modules
import {Keyboard, Navigation, Pagination} from "swiper/modules";
import Contact from "./contact/Contact";
import lineImg from "../../../../assets/website-images/home/line.png"
import {SearchIconLarge, SelectedSearchIconLarge} from "../../../../assets/web-icons/defaultWebIcons";
import {AiFillInstagram} from "react-icons/ai";
import {BsTwitterX} from "react-icons/bs";
import {motion, useInView} from 'framer-motion';
import bio1 from "../../../../assets/website-images/home/bio/bio-1.png"
import bio2 from "../../../../assets/website-images/home/bio/bio-2.png"
import bio3 from "../../../../assets/website-images/home/bio/bio-3.png"
import bio4 from "../../../../assets/website-images/home/bio/bio-4.png"

export default function Home() {
    const ref = useRef(null);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref5 = useRef(null);
    const ref8 = useRef(null);
    const isInView = useInView(ref, { once: true }); // Only trigger once
    const isInView1 = useInView(ref1, { once: true });
    const isInView2 = useInView(ref2, { once: true });
    const isInView3 = useInView(ref3, { once: true });
    const isInView5 = useInView(ref5, { once: true });
    const isInView8 = useInView(ref8, { once: true });
    const containerVariants = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2, // Delay between each icon's animation
            },
        },
    };

    const containerVariants2 = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Staggering the animation of each child by 0.3 seconds
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, x: 100 },  // Start with opacity 0 and 100px to the right
        show: { opacity: 1, x: 0 },      // Fade in and move to original position
    };

    const cardVariants = {
        hidden: { opacity: 0, x: -100 }, // Start with opacity 0 and 100px to the left
        show: { opacity: 1, x: 0 },      // Fade in and move to original position
    };


    return(
        <>
            <div className={'home-banner'}>
                {/*<motion.span*/}
                {/*    className={'scroll-icon'}*/}
                {/*    animate={{ y: [0, -10, 0] }} // Moves up by 10px and then back to the original position*/}
                {/*    transition={{*/}
                {/*        duration: 1.5,  // Duration of one cycle of the animation*/}
                {/*        repeat: Infinity, // Infinite loop*/}
                {/*        repeatType: "loop", // Keeps looping the animation*/}
                {/*        ease: "easeInOut", // Smooth ease in and out*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <img src={scroll} alt="Scroll Icon" />*/}
                {/*</motion.span>*/}
                <Container className={'h-100'}>
                    <Row  className={'h-100 align-items-center'}>
                        <Col md={12} lg={7} xl={5}>
                            <motion.h4
                                initial={{ opacity: 0, y: 100 }}  // Start with opacity 0 and 100px to the right
                                animate={{ opacity: 1, y: 0 }}    // Fade in and move to its original position
                                transition={{ duration: 1 }}      // Duration of the animation
                            >
                                Welcome to uTrust
                            </motion.h4>
                            <motion.h1
                                initial={{ opacity: 0, y: 100 }}  // Start with opacity 0 and 100px to the right
                                animate={{ opacity: 1, y: 0 }}    // Fade in and move to its original position
                                transition={{ duration: 1 }}      // Duration of the animation
                            >
                                Explore the Frontier of National Security with uTrust ABIS
                            </motion.h1>
                            {/*<motion.button*/}
                            {/*    className={'u-btn btn'}*/}
                            {/*    initial={{ opacity: 0, y: 100 }}  // Start with opacity 0 and 100px to the right*/}
                            {/*    animate={{ opacity: 1, y: 0 }}    // Fade in and move to its original position*/}
                            {/*    transition={{ duration: 1 }}      // Duration of the animation*/}
                            {/*>*/}
                            {/*    Explore More <span><GoArrowRight /></span>*/}
                            {/*</motion.button>*/}
                            <motion.div
                                className={'social-sec'}
                                variants={containerVariants}
                                initial="hidden"
                                animate="show"
                            >
                                <p>Follow us</p>
                                <motion.span variants={itemVariants}><FaFacebookF /></motion.span>
                                <motion.span variants={itemVariants}><AiFillInstagram /></motion.span>
                                <motion.span variants={itemVariants}><BsTwitterX /></motion.span>
                                <motion.span variants={itemVariants}><FaLinkedinIn /></motion.span>
                            </motion.div>
                            {/*<div className={'chat-icons'}>*/}
                            {/*    <span><ChatIcon/></span>*/}
                            {/*    <span><WhatsAppIcon/></span>*/}
                            {/*</div>*/}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="home-about">
                <Container>
                    <Row>
                        <Col md={12} lg={12} xl={8}>
                            <div className={'heading-section'}  ref={ref}>
                                <motion.h4
                                    className={'subheading'}
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                >
                                    About uTrust
                                </motion.h4>
                                <motion.h2
                                    className={'heading'}
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                >
                                    Biometric Solution Streamline Identity Management For Millions
                                </motion.h2>
                                <motion.p
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                    className='para'
                                >
                                    uTrust is the leading face recognition provider for security &
                                    identification. The world's fastest & most accurate liveness
                                    detection solution
                                </motion.p>
                                <motion.p
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                    className='para'
                                >
                                    uTrust's spoof detection technology effectively keeps out 53+
                                    spoofing attacks including deepfakes with a 100% accuracy. Founded
                                    in 2022, Facia aims to verify 8 billion faces globally
                                </motion.p>

                            </div>

                            <motion.div className="cards mb-3 mb-lg-0"
                                        variants={containerVariants2}
                                        initial="hidden"
                                 animate={isInView ? "show" : "hidden"}
                                 ref={ref}
                            >
                                <Row>
                                    <Col md={4}>
                                        <motion.div className="about-card mb-3 mb-md-0" variants={cardVariants}>
                                            <img src={icon1} alt="Empathy Icon 1" />
                                            <h4>Empathy</h4>
                                            <p>We understand the human side of the technology.</p>
                                        </motion.div>
                                    </Col>
                                    <Col md={4}>
                                        <motion.div className="about-card mb-3 mb-md-0" variants={cardVariants}>
                                            <img src={icon2} alt="Empathy Icon 2" />
                                            <h4>Morality</h4>
                                            <p>We prioritise ethics in every decision we make.</p>
                                        </motion.div>
                                    </Col>
                                    <Col md={4}>
                                        <motion.div className="about-card mb-3 mb-md-0" variants={cardVariants}>
                                            <img src={icon3} alt="Empathy Icon 3" />
                                            <h4>Agile</h4>
                                            <p>We adapt and innovate swiftly in a dynamic tech landscape.</p>
                                        </motion.div>
                                    </Col>
                                </Row>
                            </motion.div>
                        </Col>
                        <Col md={12} lg={12} xl={4} className={'d-none d-xl-block'}>
                            <motion.img
                                src={aboutImg}
                                alt=""
                                className="about-img"
                                initial={{ opacity: 0, scale: 0.8 }} // Start with opacity 0 and scaled down
                                animate={isInView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }} // Fade in and scale up
                                transition={{ duration: 1 }} // Duration of the animation
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'home-services'}>
                <div className={'heading-section'} ref={ref1}>
                    <motion.h4
                        className={'subheading'}
                        initial={{ opacity: 0, y: 100 }}
                        animate={isInView1 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                        transition={{ duration: 1 }}
                    >
                        Our Services
                    </motion.h4>
                    <motion.h2
                        className={'heading'}
                        initial={{ opacity: 0, y: 100 }}
                        animate={isInView1 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                        transition={{ duration: 1 }}
                    >
                        Comprehensive Biometric Ecosystem
                    </motion.h2>
                </div>
                <div className={'line-sec d-none d-xxl-block'}>
                    <img src={lineImg}/>
                    <div className={'line-content'}>
                        <div className={'icon'}>
                            <SearchIconLarge/>
                        </div>
                        <h4>Identity Management Solutions </h4>
                        <p>Identity Management Solutions ensure secure and efficient user authentication and access control.</p>
                        <span>Read More</span>
                    </div>
                    <div className={'line-box line-box-1'}>
                        <p>Biometrics System Integration <span><GoDotFill /></span></p>
                    </div>
                    <div className={'line-box line-box-2'}>
                        <p>Forensic Biometrics Solutions <span><GoDotFill /></span></p>
                    </div>
                    <div className={'line-box line-box-3'}>
                        <p>ID Solutions <span><GoDotFill /></span></p>
                    </div>
                    <div className={'line-box line-box-4'}>
                        <p>Biometrics Consultations <span><GoDotFill /></span></p>
                    </div>
                    <div className={'line-box line-box-5'}>
                        <p>Identity Management Solutions </p>
                        <span><SelectedSearchIconLarge/></span>
                    </div>
                </div>
                <div className={'home-service-mobile d-block d-xxl-none'}>
                    <div className="home-service-slider">
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            centeredSlides={false}
                            grabCursor={true}
                            keyboard={{
                                enabled: true,
                            }}
                            breakpoints={{
                                600: {
                                    sliderPerView: 1
                                },
                                769: {
                                    slidesPerView: 1,
                                },
                                992: {
                                    sliderPerView: 1
                                },
                                1200: {
                                    slidesPerView: 1
                                },
                                1400: {
                                    slidesPerView: 1
                                }
                            }}
                            modules={[Keyboard, Navigation, Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className={'line-content-slider'}>
                                    <div className={'icon'}>
                                        <SearchIconLarge/>
                                    </div>
                                    <h4>Identity Management Solutions </h4>
                                    <p>Identity Management Solutions ensure secure and efficient user authentication and access control.</p>
                                    <span>Read More</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={'line-content-slider'}>
                                    <div className={'icon'}>
                                        <SearchIconLarge/>
                                    </div>
                                    <h4>Identity Management Solutions </h4>
                                    <p>Identity Management Solutions ensure secure and efficient user authentication and access control.</p>
                                    <span>Read More</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={'line-content-slider'}>
                                    <div className={'icon'}>
                                        <SearchIconLarge/>
                                    </div>
                                    <h4>Identity Management Solutions </h4>
                                    <p>Identity Management Solutions ensure secure and efficient user authentication and access control.</p>
                                    <span>Read More</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={'line-content-slider'}>
                                    <div className={'icon'}>
                                        <SearchIconLarge/>
                                    </div>
                                    <h4>Identity Management Solutions </h4>
                                    <p>Identity Management Solutions ensure secure and efficient user authentication and access control.</p>
                                    <span>Read More</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={'line-content-slider'}>
                                    <div className={'icon'}>
                                        <SearchIconLarge/>
                                    </div>
                                    <h4>Identity Management Solutions </h4>
                                    <p>Identity Management Solutions ensure secure and efficient user authentication and access control.</p>
                                    <span>Read More</span>
                                </div>
                            </SwiperSlide>
                        </Swiper>

                    </div>
                </div>
            </div>
            <div className="home-featured-products">
                <Container>
                    <Row>
                        <Col>
                            <div className="headers">
                                <div ref={ref2}>
                                    <motion.h4
                                        className={'subheading'}
                                        initial={{ opacity: 0, y: 100 }}
                                        animate={isInView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                        transition={{ duration: 1 }}
                                    >
                                        Featured Products
                                    </motion.h4>
                                    <motion.h2
                                        className={'heading'}
                                        initial={{ opacity: 0, y: 100 }}
                                        animate={isInView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                        transition={{ duration: 1 }}
                                    >
                                        Biometric Solutions & Identity Products
                                    </motion.h2>
                                </div>

                                {/*<motion.button*/}
                                {/*    initial={{ opacity: 0, y: 100 }}*/}
                                {/*    animate={isInView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}*/}
                                {/*    transition={{ duration: 1.6 }}*/}
                                {/*    className={'u-btn btn d-none d-lg-block'}>View All <span><GoArrowRight /></span></motion.button>*/}
                            </div>

                            <div className="cards" ref={ref5}>
                                <Row>
                                    <Col md={6}>
                                        <motion.div
                                            className="product-card"
                                             initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                             animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                             transition={{ duration: 1.4, delay: 1 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio1}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={6}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>Automated Biometric Identification System</h4>
                                                        <p>
                                                            Explore the Frontier of National Security with
                                                            uTrust ABS: Pioneering Biometric Precision.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                    <Col md={6}>
                                        <motion.div
                                                    className="product-card"
                                                    initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                                    animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                                    transition={{ duration: 1.4, delay: 2 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio2}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={6}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>Touchless Multi-Modal Biometrics from Mobile</h4>
                                                        <p>
                                                            Discover the World of Contactless Biometric Capture with uTrust Touchless: Safety at a Distance.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                    <Col md={6}>
                                        <motion.div
                                             className="product-card"
                                             initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                             animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                             transition={{ duration: 1.4, delay: 3 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio3}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={6}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>Digital Identity</h4>
                                                        <p>
                                                            Step Into the Future with uTrust Digital Identity: Crafting Seamless Ecosystems for Tomorrow's Identity Needs.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                    <Col md={6}>
                                        <motion.div style={{display: "flex"}}
                                             className="product-card"
                                             initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                             animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                                    transition={{ duration: 1.4, delay: 4 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio4}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={6}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>uTrust ID</h4>
                                                        <p>
                                                            Empower Your Enterprise with uTrust ID: Elevating Access Management to New Heights with Unparalleled Security and Efficiency.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="home-featured-services" ref={ref3}>
                <Container className={'h-100'}>
                    <Row className={'h-100 align-items-center'}>
                        <Col lg={7} xl={6} xxl={5}>
                            <div className="info">
                                <motion.h4
                                    className={'subheading'}
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                >
                                    Featured Services
                                </motion.h4>
                                <motion.h2
                                    className={'heading'}
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1.2 }}
                                >
                                    Identity Management Solutions
                                </motion.h2>
                                <motion.p
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1.4 }}
                                    className='para'
                                >
                                    Face recognition engines employ advanced algorithms to analyze
                                    facial features and verify identities, revolutionizing security
                                    and user authentication across various applicatoin. They compare
                                    key markers like eye distance and jawline to stored templates
                                    for accurate identification.
                                </motion.p>
                                {/*<motion.button*/}
                                {/*    initial={{ opacity: 0, y: 100 }}*/}
                                {/*    animate={isInView3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}*/}
                                {/*    transition={{ duration: 1.6 }}*/}
                                {/*    className={'u-btn btn'}>Explore More <span><GoArrowRight /></span></motion.button>*/}
                            </div>
                            <div className="iam-solutions-card">
                                <h4>
                                    ID
                                    <br /> Solutions
                                </h4>
                                <div className="arrow-right">
                                    <div className="icon-container">
                                        <FaArrowRight className="arrow-right-icon" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*<div className="home-resources" ref={ref8}>*/}
            {/*    <Container>*/}
            {/*        <Row>*/}
            {/*            <Col>*/}
            {/*                <div className="headers">*/}
            {/*                    <div>*/}
            {/*                        <motion.h4*/}
            {/*                            className={'subheading'}*/}
            {/*                            initial={{ opacity: 0, y: 100 }}*/}
            {/*                            animate={isInView8 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}*/}
            {/*                            transition={{ duration: 1 }}*/}
            {/*                        >*/}
            {/*                            Resources*/}
            {/*                        </motion.h4>*/}
            {/*                        <motion.h2*/}
            {/*                            className={'heading'}*/}
            {/*                            initial={{ opacity: 0, y: 100 }}*/}
            {/*                            animate={isInView8 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}*/}
            {/*                            transition={{ duration: 1.2 }}*/}
            {/*                        >*/}
            {/*                            Stay up to date with our news*/}
            {/*                        </motion.h2>*/}
            {/*                    </div>*/}

            {/*                    <motion.button*/}
            {/*                        initial={{ opacity: 0, y: 100 }}*/}
            {/*                        animate={isInView8 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}*/}
            {/*                        transition={{ duration: 1.6 }}*/}
            {/*                        className={'u-btn btn d-none d-lg-block'}>View All <span><GoArrowRight /></span></motion.button>*/}
            {/*                </div>*/}

            {/*                <div className="swiper-container">*/}
            {/*                    <Swiper*/}
            {/*                        slidesPerView={1}*/}
            {/*                        spaceBetween={30}*/}
            {/*                        centeredSlides={false}*/}
            {/*                        grabCursor={true}*/}
            {/*                        keyboard={{*/}
            {/*                            enabled: true,*/}
            {/*                        }}*/}
            {/*                        breakpoints={{*/}
            {/*                            600: {*/}
            {/*                                sliderPerView: 1.5*/}
            {/*                            },*/}
            {/*                            769: {*/}
            {/*                                slidesPerView: 1.8,*/}
            {/*                                // slidesPerGroup: 2,*/}
            {/*                            },*/}
            {/*                            992: {*/}
            {/*                                sliderPerView: 2.2*/}
            {/*                            },*/}
            {/*                            1200: {*/}
            {/*                                slidesPerView: 2.6*/}
            {/*                            },*/}
            {/*                            1400: {*/}
            {/*                                slidesPerView: 2.9*/}
            {/*                            }*/}
            {/*                        }}*/}
            {/*                        navigation={{*/}
            {/*                            nextEl: ".swiper-button-next",*/}
            {/*                            prevEl: ".swiper-button-prev",*/}
            {/*                        }}*/}
            {/*                        modules={[Keyboard, Navigation]}*/}
            {/*                        className="mySwiper"*/}
            {/*                    >*/}
            {/*                        <SwiperSlide>*/}
            {/*                            <div className="resource-card">*/}
            {/*                                <img*/}
            {/*                                    src={news1}*/}
            {/*                                    alt=""*/}
            {/*                                    className="card-img"*/}
            {/*                                />*/}
            {/*                                <div className="card-info">*/}
            {/*                                    <p className="date">December 12, 2023</p>*/}
            {/*                                    <h4>*/}
            {/*                                        Face Matching is Only the Start: Key Technlogies in*/}
            {/*                                        Remote identity Verification*/}
            {/*                                    </h4>*/}
            {/*                                    <div className="read-more">*/}
            {/*                                        <button>Read More</button>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </SwiperSlide>*/}
            {/*                        <SwiperSlide>*/}
            {/*                            <div className="resource-card">*/}
            {/*                                <img*/}
            {/*                                    src={news2}*/}
            {/*                                    alt=""*/}
            {/*                                    className="card-img"*/}
            {/*                                />*/}
            {/*                                <div className="card-info">*/}
            {/*                                    <p className="date">December 12, 2023</p>*/}
            {/*                                    <h4>*/}
            {/*                                        Face Matching is Only the Start: Key Technlogies in*/}
            {/*                                        Remote identity Verification*/}
            {/*                                    </h4>*/}
            {/*                                    <div className="read-more">*/}
            {/*                                        <button>Read More</button>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </SwiperSlide>*/}
            {/*                        <SwiperSlide>*/}
            {/*                            <div className="resource-card">*/}
            {/*                                <img*/}
            {/*                                    src={news}*/}
            {/*                                    alt=""*/}
            {/*                                    className="card-img"*/}
            {/*                                />*/}
            {/*                                <div className="card-info">*/}
            {/*                                    <p className="date">December 12, 2023</p>*/}
            {/*                                    <h4>*/}
            {/*                                        Face Matching is Only the Start: Key Technlogies in*/}
            {/*                                        Remote identity Verification*/}
            {/*                                    </h4>*/}
            {/*                                    <div className="read-more">*/}
            {/*                                        <button>Read More</button>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </SwiperSlide>*/}
            {/*                        <SwiperSlide>*/}
            {/*                            <div className="resource-card">*/}
            {/*                                <img*/}
            {/*                                    src={news1}*/}
            {/*                                    alt=""*/}
            {/*                                    className="card-img"*/}
            {/*                                />*/}
            {/*                                <div className="card-info">*/}
            {/*                                    <p className="date">December 12, 2023</p>*/}
            {/*                                    <h4>*/}
            {/*                                        Face Matching is Only the Start: Key Technlogies in*/}
            {/*                                        Remote identity Verification*/}
            {/*                                    </h4>*/}
            {/*                                    <div className="read-more">*/}
            {/*                                        <button>Read More</button>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </SwiperSlide>*/}
            {/*                        <SwiperSlide>*/}
            {/*                            <div className="resource-card">*/}
            {/*                                <img*/}
            {/*                                    src={news2}*/}
            {/*                                    alt=""*/}
            {/*                                    className="card-img"*/}
            {/*                                />*/}
            {/*                                <div className="card-info">*/}
            {/*                                    <p className="date">December 12, 2023</p>*/}
            {/*                                    <h4>*/}
            {/*                                        Face Matching is Only the Start: Key Technlogies in*/}
            {/*                                        Remote identity Verification*/}
            {/*                                    </h4>*/}
            {/*                                    <div className="read-more">*/}
            {/*                                        <button>Read More</button>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </SwiperSlide>*/}
            {/*                        <SwiperSlide>*/}
            {/*                            <div className="resource-card">*/}
            {/*                                <img*/}
            {/*                                    src={news}*/}
            {/*                                    alt=""*/}
            {/*                                    className="card-img"*/}
            {/*                                />*/}
            {/*                                <div className="card-info">*/}
            {/*                                    <p className="date">December 12, 2023</p>*/}
            {/*                                    <h4>*/}
            {/*                                        Face Matching is Only the Start: Key Technlogies in*/}
            {/*                                        Remote identity Verification*/}
            {/*                                    </h4>*/}
            {/*                                    <div className="read-more">*/}
            {/*                                        <button>Read More</button>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </SwiperSlide>*/}
            {/*                    </Swiper>*/}
            {/*                    /!* <div className="custom-navigation"> *!/*/}
            {/*                    <button className="swiper-button-prev mt-0">*/}
            {/*                        <FaArrowLeft className='arrow-icon'/>*/}
            {/*                    </button>*/}
            {/*                    <button className="swiper-button-next mb-0">*/}
            {/*                        <FaArrowRight className='arrow-icon'/>*/}
            {/*                    </button>*/}
            {/*                    /!* </div> *!/*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</div>*/}
            {/*<Clients/>*/}
            <Contact/>
        </>
    )
}