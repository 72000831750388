import React, {useRef, useState} from "react"
import productPageImg from "../../../../../assets/website-images/product/bio-page-banner.png"
import productBannerImg from "../../../../../assets/website-images/product/product-banner.jpg";
import {motion, useInView} from "framer-motion";
import PageBanner from "../../../../components/website/pages-banner/PageBanner";
import {Col, Container, Row} from "react-bootstrap";
import box1Img from "../../../../../assets/website-images/product/bio/sec-1-box.png";
import box2Img from "../../../../../assets/website-images/product/bio/sec-1-box2.png";
import box3Img from "../../../../../assets/website-images/product/bio/sec-1-box3.png";
import box4Img from "../../../../../assets/website-images/product/bio/sec-1-box4.png";
import box5Img from "../../../../../assets/website-images/product/bio/sec-1-box5.png";
import box6Img from "../../../../../assets/website-images/product/bio/sec-1-box6.png";
import "./surveillance.scss"
import bio1 from "../../../../../assets/website-images/product/tochless/sec-3-1.png";
import bio2 from "../../../../../assets/website-images/product/tochless/sec-3-2.png";
import bio3 from "../../../../../assets/website-images/product/tochless/sec-3-3.png";
import bio4 from "../../../../../assets/website-images/product/tochless/sec-3-4.png";

export default function UtrustSurveillance() {
    const ref2 = useRef(null);
    const ref5 = useRef(null);
    const isInView2 = useInView(ref2, { once: true });
    const isInView5 = useInView(ref5, { once: true });
    const ref3 = useRef(null);
    const ref = useRef(null);
    const isInView3 = useInView(ref3, { once: true });
    const isInView = useInView(ref, { once: true }); // Only trigger once
    const [activeSection, setActiveSection] = useState(1);
    const containerVariants2 = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Staggering the animation of each child by 0.3 seconds
            },
        },
    };
    const cardVariants = {
        hidden: { opacity: 0, x: -100 }, // Start with opacity 0 and 100px to the left
        show: { opacity: 1, x: 0 },      // Fade in and move to original position
    };


    const handleSectionClick = (sectionIndex) => {
        setActiveSection(sectionIndex);
    };

    return(
        <div className={'bio-page surveilance'}>
            <PageBanner img={productPageImg} heading={'uTrust Surveillance'} bannerImg={productBannerImg}/>

            <div className={'section-1'}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <p className={''}>
                                uTrust Surveillance is a state-of-the-art facial recognition platform, meticulously customized by uTrust to deliver industry-leading accuracy, real-time performance, and scalability. Whether you need enhanced security for corporate environments, high-traffic public spaces, or access control, uTrust Surveillance provides seamless facial recognition designed for a wide range of applications.
                            </p>
                            <p className={'mb-4'}>
                                With our platform, organizations can efficiently manage security, control access, and monitor large crowds, all while ensuring privacy and compliance with regulatory standards. Built with cutting-edge AI and deep learning technologies, uTrust Surveillance provides real-time face recognition for critical security and operational efficiency.
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <h2 className={'heading'}>Key Features of uTrust Surveillance</h2>
                            <h4 className={'sub-heading-3'}>These core features define uTrust Surveillance as a leading solution in facial recognition and access control:</h4>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box green'}>
                                <img src={box1Img} />
                                <h3>Real-Time Facial Recognition</h3>
                                <p>uTrust Surveillance excels at real-time facial recognition, processing multiple camera feeds simultaneously to ensure fast and accurate identification</p>
                                <ul>
                                    <li><span>Multi-Stream Processing:</span> Handle multiple video feeds for facial recognition in real-time, ideal for busy environments such as airports or large corporate offices.</li>
                                    <li><span>High Accuracy in All Conditions:</span> uTrust Surveillance maintains high precision even in difficult scenarios, such as low light, varying angles, or partial facial obstructions.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box'}>
                                <img src={box2Img} />
                                <h3>Face Attributes Extraction</h3>
                                <p>uTrust Surveillance goes beyond mere face detection by extracting critical attributes to provide deeper insights.</p>
                                <ul>
                                    <li><span>Age Detection:</span> Estimation of the individual’s age, useful for analytics or access control in restricted areas.</li>
                                    <li><span>Gender Detection:</span> Identify gender with high accuracy for enhanced profiling and security.</li>
                                    <li><span>Face Expression Recognition:</span> Capture expressions like joy, anger, or surprise to gauge intent or mood in real-time scenarios.</li>
                                    <li><span>Confidence Scores:</span> Understand how reliable each recognition attempt is through the confidence score feature.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box'}>
                                <img src={box3Img} />
                                <h3>Liveness Detection</h3>
                                <p>Security is fortified through advanced liveness detection, which ensures that the face being recognized belongs to a live person, preventing spoofing attempts such as using photos or videos.</p>
                            </div>
                        </Col>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box green'}>
                                <img src={box4Img} />
                                <h3>Face Matching and Verification</h3>
                                <p>uTrust Surveillance is designed for quick and reliable face matching, ensuring that individuals can be verified against existing watchlists or databases in real time.
                                </p>
                                <ul>
                                    <li><span>Instant Match Results:</span> Face matching happens within milliseconds, providing instant feedback.</li>
                                    <li><span>Watchlist Integration:</span> Automatically match detected faces against pre-defined watchlists for added security.</li>
                                    <li><span>Multi-Camera Matching:</span> Matches can be made across different camera feeds for comprehensive coverage.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box green'}>
                                <img src={box5Img} />
                                <h3>Scalable Architecture</h3>
                                <p>uTrust Surveillance is designed to scale effortlessly, whether you are managing a single building or a large multi-site operation. </p>
                                <ul>
                                    <li><span>Flexible Deployments: </span> Start with small installations and scale as needed, with support for both on-premise and cloud environments.</li>
                                    <li><span>Edge Processing:</span> Perform facial recognition tasks directly on edge devices to reduce network load and speed up recognition times.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box'}>
                                <img src={box6Img} />
                                <h3>Crowd Management and Access Control</h3>
                                <p>uTrust Surveillance is the ideal solution for crowd management, ensuring that large numbers of people can be processed through access control points quickly and securely. </p>
                               <ul>
                                   <li><span>High-Speed Processing:</span> Designed to handle hundreds of faces per second, ensuring smooth operation even during peak traffic periods.</li>
                                   <li><span>Seamless Gate Integration:</span> Works seamlessly with access gates to allow quick entry while ensuring only authorized individuals are granted access.</li>
                                   <li><span>Zone Monitoring:</span> Track individuals across multiple zones or entry points in real-time to maintain oversight across complex environments</li>
                               </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="home-featured-products">
                <Container>
                    <Row>
                        <Col>
                            <div ref={ref2}>
                                <motion.h2
                                    className={'heading'}
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                >
                                    Why uTrust Surveillance?
                                </motion.h2>
                            </div>

                            <div className="cards" ref={ref5}>
                                <Row>
                                    <Col md={6}>
                                        <motion.div
                                            className="product-card"
                                            initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                            animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                            transition={{ duration: 1.4, delay: 1 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio1}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={7}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>Proven Expertise:</h4>
                                                        <p>
                                                            Built on years of experience and backed by cutting-edge biometric technology, uTrust Surveillance offers a secure and reliable solution tailored to your needs.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                    <Col md={6}>
                                        <motion.div
                                            className="product-card"
                                            initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                            animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                            transition={{ duration: 1.4, delay: 2 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio2}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={7}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>High Accuracy and Speed</h4>
                                                        <p>
                                                            The system provides real-time recognition and accurate results, ensuring smooth operations even in high-traffic environments.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                    <Col md={6}>
                                        <motion.div
                                            className="product-card"
                                            initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                            animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                            transition={{ duration: 1.4, delay: 3 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio3}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={7}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>Flexible and Scalable</h4>
                                                        <p>
                                                            From a small-scale deployment to a full-fledged multi-site system, uTrust Surveillance grows with your business needs.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                    <Col md={6}>
                                        <motion.div style={{display: "flex"}}
                                                    className="product-card"
                                                    initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                                    animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                                    transition={{ duration: 1.4, delay: 4 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio4}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={7}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>Data Privacy and Security </h4>
                                                        <p>
                                                            Our system is compliant with the latest data privacy regulations, ensuring that all personal information is securely handled and protected.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-2'}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <p className={'mb-5'}>
                                With uTrust Surveillance, your organization gains access to a complete, customizable facial recognition platform. Our advanced features, real-time processing, and scalable architecture ensure seamless integration with existing systems, allowing for enhanced security, access control, and operational efficiency.                             </p>
                            <p className={''}>
                                uTrust Surveillance is not just a facial recognition tool; it is a comprehensive security solution that delivers accurate and fast recognition, even under the most demanding conditions. Whether for corporate security, public safety, or healthcare, uTrust Surveillance is the ultimate solution for tomorrow’s security challenges.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>


        </div>
    )
}