import React from 'react';
import {Navigate} from 'react-router-dom';
import {useUser} from "../providers/UserContext";


const UnauthorizedRoute = ({ roles,  children }) => {
    const { userRole } = useUser();

    console.log("roles", roles)
    console.log("children", children)

    if(localStorage.getItem(("MusicToken"))){
        return <Navigate to="/" />;
    }
    // if (roles.includes(userRole)) {
    //     return <Navigate to="/" />;
    // }

    // else if(localStorage.getItem(("token"))){
    //     return <Navigate to="/dashboard" />;
    // }

    return (
        children
    )



};

export default UnauthorizedRoute;