import React, {useEffect, useRef, useState} from 'react';
import {Layout} from 'antd';
import {useForm} from 'react-hook-form'
import "./dashboard-layout.scss"
import {Outlet, useLocation, useNavigate} from 'react-router'
import ThemeModals from "../components/common/modals/theme-modals/ThemeModals";
import Footer from "../views/website/home/footer/Footer";
import MegaMenu from "../components/website/header/HeaderClone";

const { Header, Sider, Content } = Layout;

export default function WebLayout()  {
    const chartContainer = useRef(null);
    const location = useLocation();
    const [currentRoute, setCurrentRoute] = useState('');
    const [collapsed, setCollapsed] = useState(false);
    const [social, setSocial] = useState(false)
    const navigate = useNavigate()

    const {
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
        control,
        clearErrors
    } = useForm({
        mode: 'onChange',
    })
    const onSubmit = async (data) => {
        console.log(data)
    }

    useEffect(() => {
        setCurrentRoute(location.pathname);
    }, [location]);


    const bannerShow = ['/', '/events']
    const musicShow = ['/music', '/music-library', '/music-detail/2', '/hidden-music', '/music-detail/1']



    return (
        <Layout className={'dashboard-layout'}>
            <Layout>
                <MegaMenu/>
                <Outlet/>
                <Footer/>
            </Layout>

            <ThemeModals
                open={social}
                close={setSocial}
                title={'Share'}
                // content={<SocialShare close={setSocial}/>}
            />

        </Layout>
    );
};
