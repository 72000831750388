import React, {useRef, useState} from "react"
import productPageImg from "../../../../../assets/website-images/product/bio-page-banner.png"
import productBannerImg from "../../../../../assets/website-images/product/product-banner.jpg";
import {useInView} from "framer-motion";
import "./bio.scss"
import PageBanner from "../../../../components/website/pages-banner/PageBanner";
import {Col, Container, Row} from "react-bootstrap";
import box1Img from "../../../../../assets/website-images/product/bio/sec-1-box.png";
import box2Img from "../../../../../assets/website-images/product/bio/sec-1-box2.png";
import box3Img from "../../../../../assets/website-images/product/bio/sec-1-box3.png";
import box4Img from "../../../../../assets/website-images/product/bio/sec-1-box4.png";
import box5Img from "../../../../../assets/website-images/product/bio/sec-1-box5.png";
import box6Img from "../../../../../assets/website-images/product/bio/sec-1-box6.png";
import sec2Img from "../../../../../assets/website-images/product/bio/sec2/img1.png";
import sec2Img2 from "../../../../../assets/website-images/product/bio/sec2/img2.png";
import sec3Img from "../../../../../assets/website-images/product/bio/sec3/sec3Img.png";
import sec4Img from "../../../../../assets/website-images/product/bio/sec3/sec4Img.png";


export default function Product() {
    const ref3 = useRef(null);
    const ref = useRef(null);
    const isInView3 = useInView(ref3, { once: true });
    const isInView = useInView(ref, { once: true }); // Only trigger once
    const [activeSection, setActiveSection] = useState(1);
    const containerVariants2 = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Staggering the animation of each child by 0.3 seconds
            },
        },
    };
    const cardVariants = {
        hidden: { opacity: 0, x: -100 }, // Start with opacity 0 and 100px to the left
        show: { opacity: 1, x: 0 },      // Fade in and move to original position
    };


    const handleSectionClick = (sectionIndex) => {
        setActiveSection(sectionIndex);
    };

    return(
        <div className={'bio-page'}>
            <PageBanner img={productPageImg} heading={'Biometric SDKs'} bannerImg={productBannerImg}/>
            <div className={'bio-list'}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h2 className={'heading'}>Biometric SDKs</h2>
                            <ul>
                                <li
                                    className={activeSection === 1 ? 'active' : ''}
                                    onClick={() => handleSectionClick(1)}
                                >
                                    Multimodal Identification Engines (1: N)
                                </li>
                                <li
                                    className={activeSection === 2 ? 'active' : ''}
                                    onClick={() => handleSectionClick(2)}
                                >
                                    uFace: Advanced Face Recognition SDK for Desktop and Mobile
                                </li>
                                <li
                                    className={activeSection === 3 ? 'active' : ''}
                                    onClick={() => handleSectionClick(3)}
                                >
                                    uTrust Finger SDK: Premier Fingerprint Recognition Solution for All Platforms
                                </li>
                                <li
                                    className={activeSection === 4 ? 'active' : ''}
                                    onClick={() => handleSectionClick(4)}
                                >
                                    uTrust Iris SDK: Leading-Edge Iris Recognition for Desktop and Mobile
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={activeSection === 1 ? 'section-1' : 'section-1 hide'}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <p className={'p-s'}>Introducing our cutting-edge uTrust SDK, designed to revolutionize biometric multimodal identification with unparalleled accuracy and security. Our SDK integrates three key biometric modalities: face, fingerprint, and iris recognition, offering a robust solution for a wide range of applications from secure access control to comprehensive identity verification.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box green'}>
                                <img src={box1Img} />
                                <h3>Comprehensive Biometric Solutions</h3>
                                <p>The uTrust SDK leverages advanced algorithms to ensure high precision in identifying and verifying individuals. By combining facial recognition, fingerprint analysis, and iris scanning, our multimodal approach significantly reduces the chances of false matches and enhances overall security. This integration of multiple biometric modalities is essential in creating a more reliable and secure identification system, addressing the limitations that single-modality systems might encounter.</p>
                            </div>
                        </Col>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box'}>
                                <img src={box2Img} />
                                <h3>Optimized Performance</h3>
                                <p>Our SDK is optimized for speed, allowing for rapid template creation and matching. This ensures quick and efficient processing, crucial in high-traffic or high-security environments. Additionally, the SDK includes powerful de-duplication capabilities, ensuring that each individual is uniquely identified and preventing multiple registrations under different identities. This is particularly important in large-scale deployments where accuracy and reliability are paramount.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box'}>
                                <img src={box3Img} />
                                <h3>Flexible Integration and Modular Design</h3>
                                <p>Designed for seamless integration, uTrust SDK can be embedded into existing systems with minimal disruption. It supports various platforms and can be customized to meet specific organizational needs, ensuring a smooth and efficient deployment process. The SDK also includes separate components for face, fingerprint, and iris recognition. This modular approach allows organizations to deploy the specific modalities they need and scale up as their requirements grow.</p>
                            </div>
                        </Col>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box green'}>
                                <img src={box4Img} />
                                <h3>Advanced Security Features</h3>
                                <p>Our solution utilizes state-of-the-art algorithms designed for high accuracy and performance. These algorithms are optimized for various environmental conditions, ensuring reliable identification and verification even in challenging situations. The inclusion of advanced security features such as multi-factor authentication and real-time monitoring further enhances the robustness of our biometric solutions.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box green'}>
                                <img src={box5Img} />
                                <h3>User-Friendly and Compliant</h3>
                                <p>The uTrust SDK includes an intuitive interface that simplifies the process of managing and using biometric data, making it accessible even for users without technical expertise. Our solution is designed with compliance in mind, adhering to global standards and regulations such as GDPR. Privacy-by-design principles ensure that user data is protected and handled ethically, providing peace of mind to both users and administrators.</p>
                            </div>
                        </Col>
                        <Col lg={6} className={'p-0'}>
                            <div className={'sec-1-box'}>
                                <img src={box6Img} />
                                <h3>Applications Across Industries</h3>
                                <p>The applications of the uTrust SDK are vast, ranging from secure access control and identity verification in banking and e-commerce to enhancing patient safety and data security in healthcare. Government agencies can also benefit from our reliable and efficient biometric solutions for national ID programs, border security, and law enforcement. </p>
                                <p>By choosing uTrust SDK, you equip your organization with a powerful tool for managing digital identities, enhancing security, and ensuring compliance. Experience the future of biometric identification with uTrust SDK. </p>
                                <p>For more information and to schedule a demo, visit our website or contact our sales team.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={activeSection === 2 ? 'section-2' : 'section-2 hide'}>
                <Container>
                    <Row className={'mt-4'}>
                        <Col md={12}>
                            <p>Introducing uFace, the latest innovation in biometric technology from uTrust. Designed for both desktop and mobile platforms, uFace leverages cutting-edge facial recognition algorithms to provide unparalleled accuracy, speed, and security. Our SDK ensures robust facial verification and identification capabilities, making it ideal for a wide range of applications including secure access control, identity verification, and more. </p>
                            <p>uFace utilizes state-of-the-art facial recognition algorithms to deliver high precision in identifying and verifying individuals. This technology is optimized for various conditions, ensuring reliable performance even in low light or with varying angles. Our SDK incorporates advanced liveness detection mechanisms to prevent fraud attempts using photos or videos. It employs methods such as 3D model analysis and challenge-response techniques to ensure that the detected face is from a live person.</p>
                        </Col>
                    </Row>
                    <Row className={'mt-5'}>
                        <Col ld={6}>
                            <img src={sec2Img2} className={'img-fluid'}/>

                        </Col>
                        <Col lg={6}>
                            <p>Designed for speed, uFace allows rapid template creation and matching, ensuring quick and efficient processing, essential for high-traffic scenarios like airport security or large-scale corporate access control. The SDK is highly flexible and can be seamlessly integrated into existing systems with minimal disruption. It supports various platforms and can be customized to meet specific organizational needs, providing a scalable solution suitable for both small businesses and large enterprises. uFace is available as a standalone component or as part of a multimodal biometric suite that includes fingerprint and iris recognition. This modular approach allows organizations to deploy the specific modalities they need and scale up as their requirements grow.</p>
                        </Col>
                    </Row>
                    <Row className={'mt-5'}>
                        <Col lg={6}>
                            <p>The applications of uFace are vast. In access control, it ensures secure and efficient access to buildings and sensitive areas. For identity verification, it enhances security in banking, e-commerce, and other industries by verifying user identities with high accuracy. In healthcare, it improves patient safety and data security with biometric authentication for electronic health records. Government agencies can support national ID programs, border security, and law enforcement with reliable and efficient biometric solutions. </p>
                            <p>uFace stands out due to its high accuracy, advanced security features, and ease of integration. It is developed in-house by uTrust, ensuring continuous innovation and adherence to the highest standards of biometric technology. Trusted by organizations worldwide, uFace provides the reliability and performance needed to meet today’s security challenges. </p>
                            <p><b>For more information and to schedule a demo, visit our website or contact our sales team.</b></p>
                        </Col>
                        <Col lg={6}>
                            <img src={sec2Img} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={activeSection === 3 ? 'section-3' : 'section-3 hide'}>
                <Container>
                    <Row className={'mb-5'}>
                        <Col md={12}>
                            <p>Introducing uTrust Finger SDK, uTrust's top-tier fingerprint recognition software development kit designed for seamless integration on desktop and mobile platforms. Engineered for excellence, uTrust Finger SDK leverages cutting-edge fingerprint recognition technology to deliver superior accuracy, speed, and security, making it ideal for diverse applications such as secure access control, identity verification, and beyond. </p>
                            <p>uTrust Finger SDK employs advanced algorithms to achieve unparalleled precision in fingerprint identification and verification. This technology is robustly optimized to function effectively under various conditions, ensuring reliable performance regardless of environmental factors or skin conditions. With rapid template creation and matching capabilities, uTrust Finger SDK ensures swift and efficient processing, which is crucial for high-traffic scenarios like airports, corporate environments, and other security-sensitive areas. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} xl={7}>
                            <p>The SDK is equipped with sophisticated liveness detection features that distinguish live fingerprints from fake ones. By meticulously analyzing the unique patterns of ridges and valleys on a person’s fingertip, uTrust Finger SDK ensures that each fingerprint scan is genuine, significantly enhancing security and reliability. This feature is critical in mitigating spoofing attacks, which pose a substantial risk in biometric security systems. </p>
                            <p>uTrust Finger SDK is designed for seamless integration into existing systems with minimal disruption. It supports multiple platforms, including Windows, iOS, and Android, and offers extensive customization options to meet specific organizational needs. This flexibility makes it an ideal solution for both small businesses and large enterprises, providing a scalable and adaptable solution that evolves with organizational requirements. Furthermore, the SDK can be integrated as part of a multimodal biometric suite alongside face and iris recognition technologies. This modular approach allows organizations to deploy specific modalities as needed and expand their capabilities over time.</p>
                            <p>The versatility of uTrust Finger SDK spans various applications. In secure access control, it ensures that only authorized personnel can access restricted areas. For identity verification, it enhances security in banking, e-commerce, and other sectors by accurately confirming user identities. In healthcare, it safeguards patient data and improves access control to electronic health records. Government agencies can leverage this technology to support national ID programs, border security, and law enforcement with reliable and efficient biometric solutions. </p>
                            <p>uTrust Finger SDK stands out for its exceptional accuracy, advanced security features, and user-friendly integration. Developed in-house by uTrust, it embodies our commitment to continuous innovation and adherence to the highest standards of biometric technology. Trusted by organizations worldwide, uTrust Finger SDK delivers the reliability and performance necessary to address today’s security challenges effectively.</p>
                            <p>For more information and to schedule a demo, visit our website or contact our sales team.</p>
                        </Col>
                        <Col lg={6} xl={5} className={'text-center'}>
                            <img src={sec3Img} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={activeSection === 4 ? 'section-4' : 'section-4 hide'}>
                <Container>
                    <Row className={'mb-5'}>
                        <Col md={12}>
                            <p>Introducing uTrust Iris SDK, an advanced iris recognition solution developed by uTrust for both desktop and mobile platforms. Leveraging state-of-the-art iris recognition algorithms, uTrust Iris SDK delivers unparalleled accuracy, speed, and security, making it an ideal choice for various applications such as secure access control, identity verification, and more. </p>
                            <p>uTrust Iris SDK uses cutting-edge algorithms to capture and analyze the intricate patterns in the human iris, which are unique to every individual and stable throughout life. This ensures a highly reliable method of identification and verification. The technology is optimized for various conditions, providing consistent performance even in low light or with users wearing glasses or contact lenses. With rapid template creation and matching, the SDK ensures quick and efficient processing, essential for high-traffic environments such as airports and large corporate settings. </p>
                            <p>A key feature of uTrust Iris SDK is its sophisticated liveness detection. This prevents spoofing attempts by ensuring that the iris being scanned belongs to a live person. Techniques such as pupil response to light changes and real-time image analysis are employed to verify the authenticity of the iris, significantly enhancing security and reliability.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} xl={7}>
                            <p>Designed for seamless integration, uTrust Iris SDK can be embedded into existing systems with minimal disruption. It supports multiple platforms, including Windows, iOS, and Android, and can be customized to meet specific organizational needs. This flexibility makes it suitable for both small businesses and large enterprises, providing a scalable solution that can grow with the organization. Additionally, the SDK is available as part of a multimodal biometric suite that includes face and fingerprint recognition.</p>
                            <p>Designed for seamless integration, uTrust Iris SDK can be embedded into existing systems with minimal disruption. It supports multiple platforms, including Windows, iOS, and Android, and can be customized to meet specific organizational needs. This flexibility makes it suitable for both small businesses and large enterprises, providing a scalable solution that can grow with the organization. Additionally, the SDK is available as part of a multimodal biometric suite that includes face and fingerprint recognition.</p>
                            <p>uTrust Iris SDK stands out due to its high accuracy, advanced security features, and ease of integration. Developed in-house by uTrust, it reflects our commitment to continuous innovation and the highest standards of biometric technology. Trusted by organizations worldwide, uTrust Iris SDK provides the reliability and performance needed to meet today’s security challenges. </p>
                            <p><b>For more information and to schedule a demo, visit our website or contact our sales team.</b></p>

                        </Col>
                        <Col lg={6} xl={5} className={'text-center'}>
                            <img src={sec4Img} className={'img-fluid'} style={{maxWidth:'450px'}}/>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}