import React, {useRef} from "react"
import PageBanner from "../../../components/website/pages-banner/PageBanner";
import productPageImg from "../../../../assets/website-images/product/about-banner-img.png";
import productBannerImg from "../../../../assets/website-images/product/product-banner.jpg";
import {Col, Container, Row} from "react-bootstrap";
import {motion, useInView} from "framer-motion";
import aboutImg from "../../../../assets/website-images/product/product.png";
import cImg from "../../../../assets/website-images/product/c1.png"
import cImg2 from "../../../../assets/website-images/product/c2.png"
import secImg1 from "../../../../assets/website-images/product/sec1.png"
import secImg2 from "../../../../assets/website-images/product/sec2.png"
import secImg3 from "../../../../assets/website-images/product/sec3.png"
import secImg4 from "../../../../assets/website-images/product/sec4.png"
import secImg5 from "../../../../assets/website-images/product/sec5.png"
import "./product.scss"
import {GoArrowRight} from "react-icons/go";

export default function Product() {
    const ref3 = useRef(null);
    const ref = useRef(null);
    const isInView3 = useInView(ref3, { once: true });
    const isInView = useInView(ref, { once: true }); // Only trigger once
    const containerVariants2 = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Staggering the animation of each child by 0.3 seconds
            },
        },
    };
    const cardVariants = {
        hidden: { opacity: 0, x: -100 }, // Start with opacity 0 and 100px to the left
        show: { opacity: 1, x: 0 },      // Fade in and move to original position
    };
    return(
        <div className={'product'}>
            <PageBanner img={productPageImg} heading={'uTrust ABIS (Automated Biometric Identification System)'} bannerImg={productBannerImg}/>
            <div className={'home-about'}>
                <Container>
                    <Row>
                        <Col md={12} lg={5} className={'d-none d-lg-block'}>
                            <img src={aboutImg} className={'img-fluid'}/>
                        </Col>
                        <Col md={12} lg={7} className={'d-flex align-items-center'}>
                            <div className={'heading-section'}  ref={ref}>
                                <motion.h4
                                    className={'subheading'}
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                >
                                    Featured Products
                                </motion.h4>
                                <motion.h2
                                    className={'heading'}
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                >
                                    Explore the Frontier of National Security with uTrust ABIS: Pioneering Biometric Precision.
                                </motion.h2>
                                <motion.p
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                    className='para'
                                >
                                    uTrust ABIS is a sophisticated Automated Biometric Identification System designed to serve a broad range of applications across national security, civil identification, and law enforcement.
                                </motion.p>
                                <motion.p
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                    className='para'
                                >
                                    It provides a highly secure, multi-modal biometric platform that supports large-scale identification projects, making it indispensable for enhancing public safety and streamlining administrative processes.
                                </motion.p>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'product-civil'}>
                <Container>
                    <Row>
                        <div className={'heading-section'}>
                            <h2 className={'heading'}>Civil and Criminal ABIS Capabilities</h2>
                        </div>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div className={'civil-box box-1'}>
                                <div className={'img-box'}>
                                    <img src={cImg2}/>
                                </div>
                                <h4>Civil ABIS</h4>
                                <p>
                                    uTrust ABIS is perfectly suited for civil applications such as national ID programs and public welfare services. It ensures the integrity and efficiency of civil registries by providing accurate, fast, and reliable biometric identification. This system plays a pivotal role in eliminating duplicate records and fraud, thereby ensuring equitable access to government services and benefits. The advanced matching algorithms guarantee precise identification, while the streamlined enrollment process simplifies citizen registration. The system's deduplication capabilities prevent redundancy, maintaining clean and trustworthy databases.
                                </p>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className={'civil-box'}>
                                <div className={'img-box'}>
                                    <img src={cImg}/>
                                </div>
                                <h4>Criminal ABIS</h4>
                                <p>
                                    In the realm of criminal justice, uTrust ABIS enhances the capabilities of law enforcement agencies by providing tools for swift and accurate suspect identification and criminal record management. It's instrumental in solving cases faster through reliable matching of biometric data such as fingerprints, faces, and iris scans against criminal databases. The efficient enrollment process ensures that biometric templates are created quickly and accurately, facilitating rapid identification and reducing investigation times.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'product-section'}>
                <Container>
                    <Row>
                        <Col lg={7} className={'d-flex align-items-center'}>
                            <div>
                                <h4>Border Security Enhancement</h4>
                                <p>uTrust ABIS offers robust solutions for border control and immigration, enabling authorities to efficiently manage and monitor cross-border movements. It assists in the verification of traveler identities at entry and exit points, ensuring secure and streamlined border control operations. By integrating with international biometric databases, it significantly aids in combating cross-border crime and enhancing national security. The system's ability to match and identify individuals accurately ensures that only authorized persons are granted access, bolstering border security.</p>
                            </div>
                        </Col>
                        <Col lg={5} className={'text-center'}>
                            <img src={secImg1} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'product-section reverse'}>
                <Container>
                    <Row className={'flex-column-reverse flex-lg-row'}>
                        <Col lg={5} className={'text-center'}>
                            <img src={secImg2} className={'img-fluid'}/>
                        </Col>
                        <Col lg={7} className={'d-flex align-items-center'}>
                            <div>
                                <h4>Forensic Applications</h4>
                                <p>For forensic departments, uTrust ABIS provides essential tools that support the identification of individuals from crime scenes. The system's ability to analyze and compare biometric data extends beyond live scans to include latent prints and other traces, thereby offering invaluable assistance in forensic investigations. This capability ensures that forensic departments can operate with enhanced accuracy and efficiency, leading to quicker resolutions of cases and a higher rate of successful prosecutions. The creation of detailed biometric templates facilitates precise matching, aiding in the swift identification of suspects.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'product-section'}>
                <Container>
                    <Row>
                        <Col lg={7} className={'d-flex align-items-center'}>
                            <div>
                                <h4>Adaptable and Scalable</h4>
                                <p>Whether deployed on-premise or as a cloud-based service, uTrust ABIS is highly scalable and can be customized to meet the unique requirements of various governmental and private entities. Its flexible architecture allows for seamless integration with existing security infrastructures, making it an optimal choice for governments looking to upgrade their biometric systems without extensive overhaul. The system's scalability ensures it can handle growing databases and increasing numbers of transactions, providing reliable performance over time.</p>                            </div>
                        </Col>
                        <Col lg={5} className={'text-center'}>
                            <img src={secImg3} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'product-section reverse'}>
                <Container>
                    <Row className={'flex-column-reverse flex-lg-row'}>
                        <Col lg={5} className={'text-center'}>
                            <img src={secImg4} className={'img-fluid'}/>
                        </Col>
                        <Col lg={7} className={'d-flex align-items-center'}>
                            <div>
                                <h4>Security and Compliance</h4>
                                <p>Built around rigorous security standards, uTrust ABIS ensures that all biometric data is encrypted both at rest and in transit. The system's architecture is designed to comply with international security and privacy guidelines, providing a trustworthy solution for handling sensitive information. The robust security measures protect against data breaches, ensuring the confidentiality and integrity of biometric information.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'product-section-2'} ref={ref3}>
                <Container>
                    <Row>
                        <Col lg={8} className={'d-flex align-items-center'}>
                            <div>
                                <h4>uTrust ABIS: Leading Biometric Security Solution</h4>
                                <p>By offering a versatile range of applications and built-in security features, uTrust ABIS stands out as a leading solution in the biometric identification market, providing reliable support for enhancing national security and public administrative operations.</p>
                                <motion.button
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1.6 }}
                                    className={'u-btn btn'}>Explore More <span><GoArrowRight /></span></motion.button>
                            </div>
                        </Col>
                        <Col lg={4} className={'text-center'}>
                            <img src={secImg5} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}