import React from "react"
import PageBanner from "../../../components/website/pages-banner/PageBanner";
import BannerImg from "../../../../assets/website-images/contact/banner-img.png"
import BannerBackImg from "../../../../assets/website-images/contact/contact-banner.jpg"
import {Col, Container, Form, Row} from "react-bootstrap"
import {FaEnvelope, FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import {AiFillInstagram} from "react-icons/ai";
import {BsTwitterX} from "react-icons/bs";
import "./contact.scss"
import {FaLocationDot, FaPhone} from "react-icons/fa6";
import {HiShare} from "react-icons/hi";
import {Controller, useForm} from "react-hook-form";
import {
    alphaNumericValidation,
    checkEmptySpaces,
    EmailValidation,
    maxLength,
    PhoneValidation,
    Required
} from "../../../utils/patterns";
import TextInput from "../../../components/common/input-field/TextInput";
import PhoneField from "../../../components/common/phone-field/PhoneField";
import DescriptionField from "../../../components/common/description-field/DescriptionField";
import {motion} from "framer-motion";
import {GoArrowRight} from "react-icons/go";

export default function Contact() {
    const {
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
        control,
        clearErrors,
        watch
    } = useForm({
        mode: 'onChange',
    })
    const onSubmit = async (data) => {
        console.log(data)
    }
    return(
        <div className={'contact-page'}>
            <PageBanner heading={'Contact'} img={BannerImg} bannerImg={BannerBackImg}/>
            <div className={'contact-info'}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h2 className={'heading'}>Get In Touch</h2>
                        </Col>
                        <Col md={12}>
                            <div className={'info-section'}>
                                <ul>
                                    <li>
                                        <div className={'info-box'}>
                                            <span className={'icon'}><FaLocationDot /></span>
                                            <h5>Location</h5>
                                            <p>Saudi Arabia - Riyadh - An Nakheel 12383</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'info-box'}>
                                            <span className={'icon'}><FaEnvelope /></span>
                                            <h5>Email</h5>
                                            <p>info@utrust.sa</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'info-box'}>
                                            <span className={'icon'}><FaPhone /></span>
                                            <h5>Phone Number</h5>
                                            <p>+966112699918</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'info-box'}>
                                            <span className={'icon'}><HiShare /></span>
                                            <h5>Socail Media</h5>
                                            <div style={{display:"flex", justifyContent: 'center', gap:"20px", flexWrap:"wrap"}}>
                                                <span><FaFacebookF /></span>
                                                <span><AiFillInstagram /></span>
                                                <span><BsTwitterX /></span>
                                                <span><FaLinkedinIn /></span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'contact-form'}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Container>
                        <Row className={'justify-content-center'}>
                            <Col lg={12} xl={11}>
                                <Row>
                                    <Col md={12} className={'text-center'}>
                                        <h3 className={'subheading'}>Contact us</h3>
                                        <h2 className={'heading'}>We look forward to hearing from you</h2>
                                        <p>Fill out the form and we will get back to you as soon as possible</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className={'dfields'}>
                                            <Controller
                                                name="f_name"
                                                defaultValue={''}
                                                control={control}
                                                rules={{required: Required, maxLength: maxLength(100), pattern:alphaNumericValidation, validate: checkEmptySpaces,}}
                                                render={({field: {name, ...field}}) => (
                                                    <TextInput
                                                        placeholder={'Name'}
                                                        label={'Your first name'}
                                                        type={'text'}
                                                        field={field}
                                                        errors={errors.f_name}
                                                        className={'required'}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className={'dfields'}>
                                            <Controller
                                                name="email"
                                                defaultValue={''}
                                                control={control}
                                                rules={{required: Required,pattern:EmailValidation, maxLength: maxLength(100)}}
                                                render={({field: {name, ...field}}) => (
                                                    <TextInput
                                                        placeholder={'Your email here'}
                                                        label={'Email Address'}
                                                        type={'email'}
                                                        field={field}
                                                        errors={errors.email}
                                                        className={'required'}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className={'dfields'}>
                                            <Controller
                                                name="l_name"
                                                defaultValue={''}
                                                control={control}
                                                rules={{required: Required, maxLength: maxLength(100), pattern:alphaNumericValidation,  validate: checkEmptySpaces}}
                                                render={({field: {name, ...field}}) => (
                                                    <TextInput
                                                        placeholder={'Your last name'}
                                                        label={'Last Name'}
                                                        type={'text'}
                                                        field={field}
                                                        errors={errors.l_name}
                                                        className={'required'}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className={'dfields'}>
                                            <Controller
                                                name="phone"
                                                defaultValue={''}
                                                control={control}
                                                rules={{required: Required, pattern:PhoneValidation, maxLength: maxLength(100)}}
                                                render={({field: {name, ...field}}) => (
                                                    <PhoneField
                                                        label={'Phone'}
                                                        className={'required'}
                                                        field={field}
                                                        errors={errors.phone}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className={'dfields'}>
                                            <Controller
                                                name="message"
                                                defaultValue={''}
                                                control={control}
                                                rules={{ maxLength: maxLength(1000)}}
                                                render={({field: {name, ...field}}) => (
                                                    <DescriptionField
                                                        label={'Your Message'}
                                                        placeholder={'message'}
                                                        field={field}
                                                        errors={errors.message}
                                                        autoSize={false}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12} className={'mt-4 text-center'}>
                                        <motion.button
                                            className={'u-btn btn'}
                                            initial={{ opacity: 0, y: 100 }}  // Start with opacity 0 and 100px to the right
                                            animate={{ opacity: 1, y: 0 }}    // Fade in and move to its original position
                                            transition={{ duration: 1 }}      // Duration of the animation
                                        >
                                            Enquire Now <span><GoArrowRight /></span>
                                        </motion.button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Container>
                </Form>
            </div>
        </div>
    )
}