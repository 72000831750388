import React, {useRef} from "react"
import PageBanner from "../../../components/website/pages-banner/PageBanner";
import {Col, Container, Row} from "react-bootstrap"
import aboutImg from "../../../../assets/website-images/about/about.png"
import "./about.scss"
import {motion, useInView} from "framer-motion";
import missionImg from "../../../../assets/website-images/about/mission.png"
import aboutPageImg from "../../../../assets/website-images/about/about-banner.png"
import aboutBannerImg from "../../../../assets/website-images/about/about-banner-b.jpg"

export default function About() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true }); // Only trigger once

    const containerVariants2 = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Staggering the animation of each child by 0.3 seconds
            },
        },
    };
    const cardVariants = {
        hidden: { opacity: 0, x: -100 }, // Start with opacity 0 and 100px to the left
        show: { opacity: 1, x: 0 },      // Fade in and move to original position
    };
    return(
        <div className={'about'}>
            <PageBanner img={aboutPageImg} heading={'About Us'} bannerImg={aboutBannerImg}/>
            <div className={'home-about'}>
                <Container>
                    <Row>
                        <Col md={12} lg={7} className={'d-flex align-items-center'}>
                            <div className={'heading-section'}  ref={ref}>
                                <motion.h4
                                    className={'subheading'}
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                >
                                    About uTrust
                                </motion.h4>
                                <motion.h2
                                    className={'heading'}
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                >
                                    Biometric Solution Streamline Identity Management For Millions
                                </motion.h2>
                                <motion.p
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                    className='para'
                                >
                                    At uTrust, we are pioneers in delivering advanced biometric security solutions that meet the needs of a rapidly evolving digital world. With decades of expertise in biometrics, identity management, and access control, we are committed to providing cutting-edge technology that ensures safety, security, and efficiency for businesses, governments, and organizations across the globe. Our comprehensive range of products—including surveillance systems, touchless biometrics, identity management solutions, and forensic tools—empowers our clients to build secure environments and protect their most valuable assets.
                                </motion.p>
                                <motion.p
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                    className='para'
                                >
                                    At uTrust, we are pioneers in delivering advanced biometric security solutions that meet the needs of a rapidly evolving digital world. With decades of expertise in biometrics, identity management, and access control, we are committed to providing cutting-edge technology that ensures safety, security, and efficiency for businesses, governments, and organizations across the globe. Our comprehensive range of products—including surveillance systems, touchless biometrics, identity management solutions, and forensic tools—empowers our clients to build secure environments and protect their most valuable assets.
                                </motion.p>
                                <motion.p
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                    className='para'
                                >
                                    <motion.p
                                        initial={{ opacity: 0, y: 100 }}
                                        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                        transition={{ duration: 1 }}
                                        className='para'
                                    >
                                        At uTrust, we are pioneers in delivering advanced biometric security solutions that meet the needs of a rapidly evolving digital world. With decades of expertise in biometrics, identity management, and access control, we are committed to providing cutting-edge technology that ensures safety, security, and efficiency for businesses, governments, and organizations across the globe. Our comprehensive range of products—including surveillance systems, touchless biometrics, identity management solutions, and forensic tools—empowers our clients to build secure environments and protect their most valuable assets.
                                    </motion.p>                                </motion.p>

                            </div>
                        </Col>
                        <Col md={12} lg={5}>
                            <img src={aboutImg} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/*<div className={'about-boxes'}>*/}
            {/*    <Container>*/}
            {/*        <motion.div className="cards"*/}
            {/*                    variants={containerVariants2}*/}
            {/*                    initial="hidden"*/}
            {/*                    animate={isInView ? "show" : "hidden"}*/}
            {/*                    ref={ref}*/}
            {/*        >*/}
            {/*            <Row>*/}
            {/*                <Col md={12} lg={4}>*/}
            {/*                    <motion.div className="about-card" variants={cardVariants}>*/}
            {/*                        <span><AboutIcon1/></span>*/}
            {/*                        <h4>Empathy</h4>*/}
            {/*                        <p>We understand the human side of the technology.</p>*/}
            {/*                    </motion.div>*/}
            {/*                </Col>*/}
            {/*                <Col md={12} lg={4}>*/}
            {/*                    <motion.div className="about-card" variants={cardVariants}>*/}
            {/*                        <span><AboutIcon2/></span>*/}
            {/*                        <h4>Morality</h4>*/}
            {/*                        <p>We prioritize ethics in every decision we make.</p>*/}
            {/*                    </motion.div>*/}
            {/*                </Col>*/}
            {/*                <Col md={12} lg={4}>*/}
            {/*                    <motion.div className="about-card mb-0" variants={cardVariants}>*/}
            {/*                       <span><AboutIcon3/></span>*/}
            {/*                        <h4>Agile</h4>*/}
            {/*                        <p>We adapt and innovate swiftly in a dynamic tech landscape.</p>*/}
            {/*                    </motion.div>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </motion.div>*/}
            {/*    </Container>*/}
            {/*</div>*/}

            <div className={'about-mission'}>
                <Container>
                    <Row className={'justify-content-between'}>
                        <Col md={12} xl={8}>
                            <div className={'mission-section'}>
                                <div className={'mission-box'}>
                                    <div className={'head'}>
                                        <h2 className={'heading'}>Our Mission</h2>
                                    </div>
                                    <div className={'content'}>
                                        <h4> Delivering  Through Trust and Innovation</h4>
                                        <p className={'mb-3'}>Our mission at uTrust is to create a safer, more secure world through innovative biometric solutions. We aim to empower businesses and governments with the tools they need to secure their environments, protect their data, and ensure the privacy and safety of individuals. By leveraging the latest advancements in biometric technology, we strive to make security seamless, efficient, and accessible.</p>
                                        <p>We are driven by the belief that security should not be a barrier to progress. Our goal is to deliver solutions that blend cutting-edge technology with ease of use, allowing organizations to enhance their security without sacrificing user experience. Whether it's enabling secure access control, managing national identity systems, or assisting law enforcement with forensic tools, we are committed to developing solutions that make the world a safer place.</p>
                                    </div>
                                </div>
                                <div className={'mission-box'}>
                                    <div className={'head'}>
                                        <h2 className={'heading'}>Our Vision</h2>
                                    </div>
                                    <div className={'content'}>
                                        <h4> Excellence and Reliability in Every Service</h4>
                                        <p className={'mb-3'}>We are driven by the belief that security should not be a barrier to progress. Our goal is to deliver solutions that blend cutting-edge technology with ease of use, allowing organizations to enhance their security without sacrificing user experience. Whether it's enabling secure access control, managing national identity systems, or assisting law enforcement with forensic tools, we are committed to developing solutions that make the world a safer place.</p>
                                        <p className={'mb-3'}>We strive to be the global leader in biometric technology, recognized for our commitment to excellence, integrity, and the delivery of transformative solutions. By fostering a culture of continuous improvement and customer-centric innovation, we aim to set new standards in the biometric industry, helping our clients navigate an increasingly connected and complex world with confidence.</p>
                                        <p>We see a future where security is no longer reactive, but proactive—where biometric technologies work in harmony with artificial intelligence to predict, prevent, and protect against emerging threats. With this vision in mind, we are dedicated to helping our clients stay one step ahead in safeguarding their people, assets, and data.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} xl={4} className={'text-center d-none d-xl-block'}>
                            <img src={missionImg} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'about-values'}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={'heading-section'}>
                                <h4 className={'subheading'}>Core Values</h4>
                                <h2 className={'heading'}>Commitment to Quality and Innovation</h2>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={'value-list-section'}>
                                <ul>
                                    <li>
                                        <h4>Partnership</h4>
                                        <p>We make sure that any work effort is driven by a strong sense of team spirit and partnership with an aim to build strong ties with our business partners and stakeholders.</p>
                                    </li>
                                    <li>
                                        <h4>Quality</h4>
                                        <p>We make sure that any work effort is driven by a strong sense of team spirit and partnership with an aim to build strong ties with our business partners and stakeholders.</p>
                                    </li>
                                    <li>
                                        <h4>Commitment</h4>
                                        <p>We make sure that any work effort is driven by a strong sense of team spirit and partnership with an aim to build strong ties with our business partners and stakeholders.</p>
                                    </li>
                                    <li>
                                        <h4>Reliability</h4>
                                        <p>We make sure that any work effort is driven by a strong sense of team spirit and partnership with an aim to build strong ties with our business partners and stakeholders.</p>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}