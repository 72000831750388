import React, {useRef, useState} from "react"
import productPageImg from "../../../../../assets/website-images/product/digital/digital-page-img.png"
import productBannerImg from "../../../../../assets/website-images/product/product-banner.jpg";
import {useInView} from "framer-motion";
import PageBanner from "../../../../components/website/pages-banner/PageBanner";
import {Col, Container, Row} from "react-bootstrap";
import sec1Img from "../../../../../assets/website-images/product/digital/sec-1-img.png";
import sec3Img from "../../../../../assets/website-images/product/digital/sec-3-img.png";
import sec2box1 from "../../../../../assets/website-images/product/digital/digital-boxs/b1.png";
import sec2box2 from "../../../../../assets/website-images/product/digital/digital-boxs/b2.png";
import sec2box3 from "../../../../../assets/website-images/product/digital/digital-boxs/b3.png";
import sec2box4 from "../../../../../assets/website-images/product/digital/digital-boxs/b4.png";
import sec2box5 from "../../../../../assets/website-images/product/digital/digital-boxs/b5.png";

import "./digital.scss"

export default function DigitalPage() {
    const ref3 = useRef(null);
    const ref = useRef(null);
    const isInView3 = useInView(ref3, { once: true });
    const isInView = useInView(ref, { once: true }); // Only trigger once
    const [activeSection, setActiveSection] = useState(1);
    const containerVariants2 = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Staggering the animation of each child by 0.3 seconds
            },
        },
    };
    const cardVariants = {
        hidden: { opacity: 0, x: -100 }, // Start with opacity 0 and 100px to the left
        show: { opacity: 1, x: 0 },      // Fade in and move to original position
    };


    const handleSectionClick = (sectionIndex) => {
        setActiveSection(sectionIndex);
    };

    return(
        <div className={'digital-page'}>
            <PageBanner img={productPageImg} heading={'uTrust Digital Identity'} bannerImg={productBannerImg}/>
            <div className={'dig-section-1'}>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <h3 className={''}>Step into the Future with uTrust Digital Identity</h3>
                            <h5>Crafting Seamless Ecosystems for Tomorrow's Identity Needs.</h5>
                            <p>uTrust Digital Identity is a robust platform designed to streamline the management and authentication of digital identities across various applications and systems. Our solution offers a blend of high security, user convenience, and comprehensive management tools, ideal for enterprises looking to enhance their digital infrastructure.</p>
                        </Col>
                        <Col lg={6} className={'text-center'}>
                            <img src={sec1Img} className={'img-fluid ms-auto'}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'dig-section-2'}>
                <Container>
                    <Row>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec2box1}/>
                                <h4>Key Features and Benefits</h4>
                                <p>Integrated Management: uTrust Digital Identity provides a unified approach to manage user identities across all platforms. From onboarding to offboarding, our system simplifies user lifecycle management through automated workflows and centralized administration.</p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec2box2}/>
                                <h4>Advanced Security Protocols</h4>
                                <p>With features like multi-factor authentication (MFA) and conditional access based on user activity and location, uTrust ensures that only legitimate users can access sensitive data and applications.</p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec2box3}/>
                                <h4>Seamless User Experience</h4>
                                <p>Employ single sign-on (SSO) capabilities that allow users to access multiple applications with one set of credentials, significantly enhancing user experience without compromising security.</p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec2box4}/>
                                <h4>Customizable and Scalable</h4>
                                <p> Adapt to any business environment with a flexible solution that grows with your needs. Whether you are a small business or a large enterprise, uTrust Digital Identity can be tailored to meet your specific requirements.</p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec2box5}/>
                                <h4>Compliance and Reliability</h4>
                                <p>Ensure compliance with global data protection regulations such as GDPR and HIPAA. Our platform is built on a secure, reliable infrastructure that prioritizes data integrity and privacy.</p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'dig-section-3'}>
                <Container>
                    <Row className={'mb-5'}>
                        <Col md={12}>
                            <h2 className={'heading'}>Why Choose uTrust Digital Identity?</h2>
                            <p>Choose uTrust for its cutting-edge technology and commitment to innovation. With support from a dedicated team and access to ongoing updates and enhancements, uTrust Digital Identity is your partner in navigating the complex landscape of digital identity management By incorporating industry-leading features and focusing on a user-centric approach, uTrust Digital Identity provides a powerful tool for any organization aiming to secure and streamline their identity management processes. This solution not only protects against unauthorized access but also enhances operational efficiency and user satisfaction.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className={'text-center'}>
                            <img src={sec3Img} className={'img-fluid ms-auto'}/>
                        </Col>
                        <Col lg={6}>
                            <p className={'mt-5'}>Choose uTrust for its cutting-edge technology and commitment to innovation. With support from a dedicated team and access to ongoing updates and enhancements, uTrust Digital Identity is your partner in navigating the complex landscape of digital identity management By incorporating industry-leading features and focusing on a user-centric approach, uTrust Digital Identity provides a powerful tool for any organization aiming to secure and streamline their identity management processes. </p>
                            <p>This solution not only protects against unauthorized access but also enhances operational efficiency and user satisfaction.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}