import React from "react";
import '../src/assets/scss/fonts2.scss'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'antd/dist/antd.css'
import "../src/assets/scss/default.scss"
import "../src/assets/scss/custom.scss"
import "../src/assets/scss/colors.scss"
import "../src/assets/scss/tabs.scss"
import '../src/assets/scss/table.scss'

import "../src/assets/scss/notification.scss"
import {BrowserRouter} from 'react-router-dom';
import RouteConfig from "./app/utils/RouteConfig";
import antdTheme from "./antdTheme";
import {ConfigProvider} from "antd";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function App() {
    return (
        <div className="App">
            <ConfigProvider theme={antdTheme}>
                <BrowserRouter>
                    <RouteConfig/>
                </BrowserRouter>
            </ConfigProvider>
        </div>
    );
}

export default App;
