import React, {useRef, useState} from "react"
import productPageImg from "../../../../assets/website-images/product/iam/iam-product-img.png"
import productBannerImg from "../../../../assets/website-images/product/product-banner.jpg";
import {useInView} from "framer-motion";
import PageBanner from "../../../components/website/pages-banner/PageBanner";
import {Col, Container, Row} from "react-bootstrap";

import "./services.scss"

import sec3Img from "../../../../assets/website-images/product/iam/sec3.png";


export default function ServicesPage() {
    const ref3 = useRef(null);
    const ref = useRef(null);
    const isInView3 = useInView(ref3, { once: true });
    const isInView = useInView(ref, { once: true }); // Only trigger once
    const [activeSection, setActiveSection] = useState(1);
    const containerVariants2 = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Staggering the animation of each child by 0.3 seconds
            },
        },
    };
    const cardVariants = {
        hidden: { opacity: 0, x: -100 }, // Start with opacity 0 and 100px to the left
        show: { opacity: 1, x: 0 },      // Fade in and move to original position
    };


    const handleSectionClick = (sectionIndex) => {
        setActiveSection(sectionIndex);
    };

    return(
        <div className={'services'}>
            <PageBanner img={productPageImg} heading={'Services'} bannerImg={productBannerImg}/>

            <div className={'section-3'}>
                <Container>
                    <Row>
                        <Col lg={6} xl={7}>
                            <h2 className={'heading mb-4'}>uTrust Surveillance and Access Control</h2>
                            <h3 className={'sub-heading-3'}>Next-Generation Security Through Advanced Biometric and Real-Time Monitoring Solutions</h3>
                            <p>uTrust Surveillance and Access Control combines advanced facial recognition, machine learning algorithms, and real-time monitoring to deliver a comprehensive, cutting-edge security solution. The system is designed to ensure the highest level of security by allowing only authorized personnel to access restricted areas while proactively monitoring for unauthorized entries or potential threats. The integration of AI-driven facial recognition with biometric authentication ensures rapid, accurate identification, reducing the likelihood of human error. </p>
                            <p>One of the technological advantages of uTrust’s system is its ability to process large volumes of data with minimal latency, thanks to its edge computing capabilities. By processing data locally at the source (i.e., cameras), uTrust reduces the need for constant data transfers to the cloud, ensuring faster real-time decision-making and improved operational efficiency. Additionally, the system’s cloud integration offers centralized control over multiple sites, enabling organizations to manage security for multiple facilities from a single dashboard, enhancing response times in emergencies. </p>
                            <p>For high-security environments, the ability to scale effortlessly from a single site to multiple locations provides flexibility and ensures that all security protocols are uniformly enforced, regardless of the geographic spread of operations. This technology is particularly beneficial for airports, corporate campuses, and government facilities where seamless, real-time monitoring is critical. </p>
                            <p>Discover how uTrust Surveillance and Access Control can transform your security operations by visiting our website or contacting our sales team for a personalized consultation.</p>
                        </Col>
                        <Col lg={6} xl={5} className={'d-flex align-items-center'}>
                            <img src={sec3Img} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-3'}>
                <Container>
                    <Row>
                        <Col lg={6} xl={5} className={'d-flex align-items-center'}>
                            <img src={sec3Img} className={'img-fluid'}/>
                        </Col>
                        <Col lg={6} xl={7}>
                            <h2 className={'heading mb-4'}>Government and National ID Programs</h2>
                            <h3 className={'sub-heading-3'}>Empowering Nations with Secure and Scalable Identity Management Solutions</h3>
                            <p>uTrust’s Government and National ID Programs leverage sophisticated biometric technologies—facial recognition, fingerprint scanning, and iris recognition—to securely manage the identities of millions of citizens. These systems are specifically designed to handle high volumes of data while maintaining fast processing times, ensuring that governments can enroll, verify, and deduplicate identities efficiently. One key feature is uTrust’s biometric deduplication technology, which helps prevent identity fraud by ensuring that each citizen is enrolled once, making the system both secure and fair. </p>
                            <p>The scalability of uTrust’s platform ensures that governments can manage large-scale identity projects such as voter registration, social welfare distribution, and border control. Whether for managing cross-border travel or verifying voters’ identities during elections, uTrust’s biometric systems provide the foundation for secure and transparent national identity management. The platform is designed with compliance in mind, adhering to international data privacy regulations like GDPR, ensuring that citizen data is protected. </p>
                            <p>With governments increasingly relying on digital identity frameworks, uTrust’s systems offer the agility and security required to support their transformation into digital economies. Our systems can also be integrated with existing identity databases, enabling governments to enhance legacy systems while ensuring that future expansions are both seamless and secure. </p>
                            <p>Learn more about how uTrust can support your national identity initiatives by contacting our sales team.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-3'}>
                <Container>
                    <Row>
                        <Col lg={6} xl={7}>
                            <h2 className={'heading mb-4'}>Forensic Bio Analysis Tools</h2>
                            <h3 className={'sub-heading-3'}>Advancing Law Enforcement and Forensics with Biometric Analysis and Crime Scene Forensics</h3>
                            <p>uTrust’s Forensic Bio Analysis Tools are designed to assist law enforcement and forensic teams in capturing, analyzing, and matching biometric data from crime scenes. Using advanced algorithms, uTrust’s tools provide real-time identification of individuals based on fingerprints, facial data, and DNA, accelerating investigations and improving the accuracy of forensic analysis. </p>
                            <p>The technology behind uTrust’s forensic tools is designed to handle even low-quality or partial data, ensuring that valuable insights can be extracted from compromised evidence. By integrating seamlessly with national and international criminal databases, our systems allow investigators to cross-reference biometric data quickly, enhancing their ability to identify suspects and victims. This technology significantly reduces investigation times and increases the likelihood of resolving cases. </p>
                            <p>The forensic tools also offer secure data processing, ensuring that sensitive criminal evidence is handled in compliance with international standards. This makes uTrust an indispensable partner for law enforcement agencies looking to modernize their investigative capabilities and improve their forensic analysis processes. </p>
                            <p>For more information on how uTrust’s Biometric Forensic Tools can enhance your investigative capabilities, visit our website or contact our forensic solutions team.</p>
                        </Col>
                        <Col lg={6} xl={5} className={'d-flex align-items-center'}>
                            <img src={sec3Img} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-3'}>
                <Container>
                    <Row>
                        <Col lg={6} xl={5} className={'d-flex align-items-center'}>
                            <img src={sec3Img} className={'img-fluid'}/>
                        </Col>
                        <Col lg={6} xl={7}>
                            <h2 className={'heading mb-4'}>Biometric SDKs for Custom Applications:</h2>
                            <h3 className={'sub-heading-3'}>Empower Your Applications with uTrust Biometric SDKs for Unparalleled Security and Flexibility</h3>
                            <p>uTrust’s Biometric SDKs provide developers with the tools necessary to integrate world-class biometric technologies into their applications. Whether building mobile or desktop solutions, our SDKs support the incorporation of facial recognition, fingerprint, and iris biometrics, making them ideal for industries that demand top-tier security, such as banking, healthcare, and government services. </p>
                            <p>The SDKs are designed with flexibility in mind, ensuring seamless integration across various platforms and operating systems. uTrust’s Biometric SDKs offer fast, secure, and scalable solutions that support high volumes of users without compromising performance. Each SDK is equipped with advanced encryption protocols to secure sensitive data, ensuring that personal information remains protected at all times. The modular design allows developers to choose the specific biometric functionalities they need, minimizing integration complexities and time-to-market for new applications. </p>
                            <p>uTrust’s SDKs empower developers to create highly secure and customized authentication experiences, making the process of building secure identity systems both faster and more reliable. Whether it’s for user authentication in fintech apps or identity verification in healthcare, uTrust’s SDKs provide the versatility and reliability that developers and enterprises need. </p>
                            <p>To discover how uTrust’s Biometric SDKs can empower your development projects, visit our website or contact our team for integration options.</p>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className={'section-3'}>
                <Container>
                    <Row>
                        <Col lg={6} xl={7}>
                            <h2 className={'heading mb-4'}>Biometric-Driven Emergency Response Solutions</h2>
                            <h3 className={'sub-heading-3'}>Rapid and Reliable Identification for Life-Saving Emergency Response Operations</h3>
                            <p>uTrust’s Biometric-Driven Emergency Response Solutions enable first responders to quickly and accurately identify individuals during critical situations. By using mobile-friendly biometric systems, responders can authenticate individuals through facial recognition or fingerprint scanning, accessing vital medical records or identification data in real-time. </p>
                            <p>The portability and versatility of this solution make it invaluable for disaster recovery, emergency medical services, and search-and-rescue operations. By providing first responders with immediate access to identification information, they can make more informed decisions and administer the correct care faster, ultimately improving outcomes for victims. </p>
                            <p>The biometric systems can be easily deployed in a wide range of environments, from remote disaster zones to urban crises, and their rugged design ensures durability in challenging conditions. uTrust’s emergency response solutions not only streamline identification processes but also contribute to more coordinated and efficient rescue efforts, potentially saving lives in high-pressure scenarios. </p>
                            <p>To learn more about how uTrust’s Emergency Response Solutions can support your life-saving initiatives, visit our website or contact our team for further information.</p>
                        </Col>
                        <Col lg={6} xl={5} className={'d-flex align-items-center'}>
                            <img src={sec3Img} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-3'}>
                <Container>
                    <Row>
                        <Col lg={6} xl={5} className={'d-flex align-items-center'}>
                            <img src={sec3Img} className={'img-fluid'}/>
                        </Col>
                        <Col lg={6} xl={7}>
                            <h2 className={'heading mb-4'}>Custom ID Systems for Enterprises</h2>
                            <h3 className={'sub-heading-3'}>Secure Your Enterprise with uTrust’s Tailored Identity and Access Management Solutions</h3>
                            <p>uTrust’s Custom Identity and Access Management (ID) Systems provide a robust framework for enterprises to securely manage user identities and access rights. The integration of biometric authentication methods, including facial recognition, fingerprint scanning, and iris recognition, ensures that only authorized personnel gain access to critical systems and data. </p>
                            <p>uTrust’s ID systems leverage multi-factor authentication (MFA) and single sign-on (SSO) functionality to simplify user access while maintaining the highest levels of security. The system is fully customizable and can be seamlessly integrated into existing IT infrastructures, providing organizations with the flexibility to scale and adapt to growing security needs. By ensuring compliance with regulations such as GDPR, HIPAA, and CCPA, uTrust’s ID solutions help enterprises avoid costly penalties while enhancing security. </p>
                            <p>With the added layer of biometric authentication, enterprises can significantly reduce the risk of identity theft or unauthorized access, providing a secure and efficient way to manage digital identities.
                                Discover how uTrust’s ID Systems can enhance your enterprise’s security by visiting our website or contacting our enterprise solutions team.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-3'}>
                <Container>
                    <Row>
                        <Col lg={6} xl={7}>
                            <h2 className={'heading mb-4'}>On-Premise Biometric Installations</h2>
                            <h3 className={'sub-heading-3'}>Full Control and Maximum Security with uTrust’s On-Premise Biometric Solutions</h3>
                            <p>uTrust’s On-Premise Biometric Installations are designed for organizations that require complete control over their biometric systems, ensuring that all data remains securely processed and stored on-site. This solution is ideal for government agencies, financial institutions, and healthcare providers with stringent data privacy and compliance requirements. </p>
                            <p>By providing localized data processing, uTrust ensures that biometric data is securely handled within the organization’s infrastructure, offering unmatched data protection. The on-premise solution is fully customizable, allowing organizations to tailor the system to their specific security needs. From initial design and deployment to ongoing maintenance and support, uTrust ensures that your biometric systems are optimized for performance and security. </p>
                            <p>To explore how uTrust’s On-Premise Biometric Installations can safeguard your sensitive data, visit our website or contact our team for a detailed consultation.</p>
                        </Col>
                        <Col lg={6} xl={5} className={'d-flex align-items-center'}>
                            <img src={sec3Img} className={'img-fluid'}/>
                        </Col>
                    </Row>
                </Container>
            </div>



        </div>
    )
}