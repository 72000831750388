import React from "react"
import "./page-banner.scss"
import {Col, Container, Row} from "react-bootstrap"

export default function PageBanner({img, heading, bannerImg}) {
    return(
        <div className={'page-banner'} style={{ backgroundImage: `url(${bannerImg})` }}>
            <Container>
                <Row>
                    <Col md={12} lg={7} className={'align-content-center'}>
                        <h1>{heading ? heading : 'Enter Text'}</h1>
                    </Col>
                    <Col md={12} lg={5} className={'text-center d-none d-lg-block'}>
                        <img src={img ? img : ''}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}