import React, {useState} from 'react';
import {Col, Container, Nav, Row} from 'react-bootstrap';
import "./mega-menu.scss"
import Logo from "../../../../assets/website-images/utrust-logo.png";
import {FaEnvelope, FaFacebookF, FaLinkedinIn, FaPhone} from "react-icons/fa";
import {Link} from "react-router-dom";
import "./header.scss"
import {FiChevronDown, FiChevronUp} from 'react-icons/fi'; // Import the arrows
import mega1 from "../../../../assets/website-images/mega/mega-1.png";
import mega2 from "../../../../assets/website-images/mega/mega-2.png";
import {motion} from 'framer-motion';
import {AiFillInstagram, AiOutlineClose} from "react-icons/ai";
import {BsTwitterX} from "react-icons/bs";
import {MenuIcon} from "../../../../assets/web-icons/defaultWebIcons";
import {useDimensions} from "./useDimension"; // Import Framer Motion

const sidebarVariants = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 100% 0%)`, // Clip path starts from the top-right corner
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2
        }
    }),
    closed: {
        clipPath: "circle(0px at 100% 0%)", // Clip path collapses to a point at the top-right corner
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40
        }
    }
};

const MegaMenu = () => {
    const [activeMenu, setActiveMenu] = useState(null);
    const [menu, setMenu] = useState(false);
    const [ref, { height }] = useDimensions(); // Use the custom hook

    const handleMouseEnter = (menuIndex) => {
        setActiveMenu(menuIndex);
    };

    const handleMouseLeave = () => {
        setActiveMenu(null);
    };

    // Animation variants for Framer Motion
    const dropdownVariants = {
        hidden: { opacity: 0, height: 0 },
        visible: { opacity: 1, height: 'auto' },
        exit: { opacity: 0, height: 0 }
    };

    return (
        <div>
            <header className={`header ${activeMenu ? 'menu-open' : ''}`}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={'menu-area'}>
                                <div>
                                    <Link to="/"> <img className={'logo cp'} src={Logo} alt="Logo" /></Link>
                                </div>
                                <div className={'menu'}>
                                    <div className={'top-menu'}>
                                        <ul className={'d-none d-md-flex'}>
                                            <li><span><FaPhone /></span> +966112699918</li>
                                            <li><span><FaEnvelope /></span> info@utrust.sa</li>
                                        </ul>
                                    </div>

                                    <div className={'main-menu'}>
                                        <Nav className="justify-content-center mega-nav d-none d-md-flex">
                                            <Nav.Item>
                                                <Nav.Link
                                                    onMouseEnter={() => handleMouseEnter(1)}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    Products
                                                    {activeMenu === 1 ? (
                                                        <FiChevronUp className="ml-2" />
                                                    ) : (
                                                        <FiChevronDown className="ml-2" />
                                                    )}
                                                </Nav.Link>

                                                {activeMenu === 1 && (
                                                    <Nav.Link
                                                        onMouseEnter={() => handleMouseEnter(1)}
                                                        onMouseLeave={handleMouseLeave}
                                                        style={{ height: '0', position: 'absolute', width: '100%', left: '0' }}
                                                    >
                                                        <motion.div
                                                            className="mega-dropdown"
                                                            initial="hidden"
                                                            animate="visible"
                                                            exit="exit"
                                                            variants={dropdownVariants}
                                                            onMouseEnter={() => handleMouseEnter(1)}
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            <Container>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className={'menu-list-box'}>
                                                                            <h5>Products</h5>
                                                                            <ul className={''}>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="/product">Automated Biometric Identification System</Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="/digital-identity">Digital Identity</Link>
                                                                                </li>
                                                                                {/*<li>*/}
                                                                                {/*    <Link to="/product">Identity Access Management</Link>*/}
                                                                                {/*</li>*/}
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="/tochless-biomatric">Touchless Biometrics</Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="/biomatric-sdks">Biometric SDKs</Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="/utrust-surveillance">uTrust Surveillance</Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="/id-solutions">ID Solutions</Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <img src={mega1} className={'img-fluid'} />
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </motion.div>
                                                    </Nav.Link>
                                                )}
                                            </Nav.Item>

                                            <Nav.Item>
                                                <Nav.Link
                                                    onMouseEnter={() => handleMouseEnter(2)}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    <Link to="/services" onClick={()=>{handleMouseLeave()}}> Services</Link>

                                                    {activeMenu === 2 ? (
                                                        <FiChevronUp className="ml-2" />
                                                    ) : (
                                                        <FiChevronDown className="ml-2" />
                                                    )}
                                                </Nav.Link>

                                                {activeMenu === 2 && (
                                                    <Nav.Link
                                                        onMouseEnter={() => handleMouseEnter(2)}
                                                        onMouseLeave={handleMouseLeave}
                                                        style={{ height: '0', position: 'absolute', width: '100%', left: '0' }}
                                                    >
                                                        <motion.div
                                                            className="mega-dropdown"
                                                            initial="hidden"
                                                            animate="visible"
                                                            exit="exit"
                                                            variants={dropdownVariants}
                                                            onMouseEnter={() => handleMouseEnter(2)}
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            <Container>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className={'menu-list-box'}>
                                                                            <h5>Services</h5>
                                                                            <ul className={''}>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="#">uTrust Surveillance and Access Control </Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="#">Government and National ID Programs </Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="#">Forensic Bio Analysis Tools</Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="#">Biometric SDKs for Custom Applications</Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="#">Biometric-Driven Emergency Response Solutions </Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="#">Custom ID Systems for Enterprises</Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="#">On-Premise Biometric Installations</Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} className={'text-center'}>
                                                                        <img src={mega2} className={'img-fluid'} />
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </motion.div>
                                                    </Nav.Link>
                                                )}
                                            </Nav.Item>

                                            <Nav.Item>
                                                <Nav.Link>
                                                    <Link to="#">Clients</Link>
                                                </Nav.Link>
                                            </Nav.Item>

                                            {/*<Nav.Item>*/}
                                            {/*    <Nav.Link>*/}
                                            {/*        <Link to="/about">About Us</Link>*/}
                                            {/*    </Nav.Link>*/}
                                            {/*</Nav.Item>*/}

                                            <Nav.Item>
                                                <Nav.Link
                                                    onMouseEnter={() => handleMouseEnter(3)}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    About Us
                                                    {activeMenu === 3 ? (
                                                        <FiChevronUp className="ml-2" />
                                                    ) : (
                                                        <FiChevronDown className="ml-2" />
                                                    )}
                                                </Nav.Link>

                                                {activeMenu === 3 && (
                                                    <Nav.Link
                                                        onMouseEnter={() => handleMouseEnter(3)}
                                                        onMouseLeave={handleMouseLeave}
                                                        style={{ height: '0', position: 'absolute', width: '100%', left: '0' }}
                                                    >
                                                        <motion.div
                                                            className="mega-dropdown"
                                                            initial="hidden"
                                                            animate="visible"
                                                            exit="exit"
                                                            variants={dropdownVariants}
                                                            onMouseEnter={() => handleMouseEnter(3)}
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            <Container>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <div className={'menu-list-box'}>
                                                                            <h5>About Us</h5>
                                                                            <ul className={''}>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="/about">About </Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="/contact">Contact Us </Link>
                                                                                </li>
                                                                                <li onClick={()=>{handleMouseLeave()}}>
                                                                                    <Link to="#">Careers</Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} className={'text-center'}>
                                                                        <img src={mega2} className={'img-fluid'} />
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </motion.div>
                                                    </Nav.Link>
                                                )}
                                            </Nav.Item>
                                        </Nav>
                                        <div className={'options d-flex d-md-none'}>
                                            {/*<span><RiSearchLine /></span>*/}
                                            <span onClick={() => setMenu(!menu)}><MenuIcon /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
            <motion.div
                className={'big-menu'}
                initial={false}
                animate={menu ? "open" : "closed"}
                variants={sidebarVariants}
                custom={height}
                ref={ref}
            >
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={'menu-header'}>
                                <div>
                                    <img className={'logo'} src={Logo} alt="Logo" />
                                </div>
                                <div>
                                  <span onClick={() => setMenu(false)} className={'close-icon'}>
                                    <AiOutlineClose />
                                  </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className={'menu-content'}>
                                <div className={'menu-list'}>
                                    <ul>
                                        <li>
                                            <Link to="/product">Products</Link>
                                        </li>
                                        <li>
                                            <Link to="/">Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/">Clients</Link>
                                        </li>
                                        <li>
                                            <Link to="/about">About Us</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className={'social-list'}>
                                    <ul>
                                        <li>
                                            <h4>Email</h4>
                                            <p>info@utrust.sa</p>
                                        </li>
                                        <li>
                                            <h4>Phone Number</h4>
                                            <p> +966112699918</p>
                                        </li>
                                        <li>
                                            <h4>Address</h4>
                                            <p>Saudi Arabia - Riyadh - Al Nakheel 12883</p>
                                        </li>
                                        <li>
                                            <h4>Social Media</h4>
                                            <div className={'social-icons'}>
                                                <span><FaFacebookF /></span>
                                                <span><AiFillInstagram /></span>
                                                <span><BsTwitterX /></span>
                                                <span><FaLinkedinIn /></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </motion.div>
        </div>
    );
};

export default MegaMenu;
