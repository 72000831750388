import React from 'react'
import Button from 'react-bootstrap/esm/Button'
import {Link} from 'react-router-dom'
import {Spin} from 'antd'
import './theme-button.scss'


export default function ThemeButton(options) {

    return (
        <>
            {options.route ? (
                <Link to={!options.disabled ? options.route ? options.route : '#' : '#'}>
                    <Button
                        type={options.type || 'button'}
                        variant={'asd'}
                        className={`theme-button ${(options.className && options.className) || ''}`}
                        // disabled={options.disabled ? options.disabled : options.loader || true}
                        disabled={options.disabled}
                        onClick={options.onClick}
                    >
                        {options.loader ? (
                            <Spin />
                        ) : (
                            <>
                                {options.prefixIcon && <span className={'prefix-icon'}> {options.prefixIcon}</span>}
                                {options.text ? options.text : 'Add Button Text'}
                                {options.suffixIcon && <span className={'suffix-icon'}>{options.suffixIcon}</span>}
                            </>
                        )}
                    </Button>
                </Link>
            ) : (
                <Button
                    onClick={options.onClick}
                    type={options.type}
                    variant={'asd'}
                    className={`theme-button ${options.className || ''}`}
                    disabled={options.loader ? true : options.disabled}
                >
                    {options.loader ? (
                        <Spin />
                    ) : (
                        <>
                            {options.prefixIcon && <span className={'prefix-icon'}> {options.prefixIcon}</span>}
                            {options.text ? options.text : 'Add Button Text'}
                            {options.suffixIcon && <span className={'suffix-icon'}>{options.suffixIcon}</span>}
                        </>
                    )}
                </Button>
            )}
        </>
    )
}
