import React from "react";
import {Input} from 'antd';
import "./text-input.scss"

export default function TextInput(options) {
    return(
        <div className={`text-field ${options.className} `}>
            {
                options.label && (
                    <label className={'field-label'}>{options.label} <span className={'steric'}>*</span></label>
                )
            }
            <Input
                {...options.rest}
                {...options.field}
                placeholder={options.placeholder}
                // prefix={options.prefix}
                status={options.errors && 'error'}
                suffix={options.suffix}
                prefix={options.prefix}
                type={options.type ? options.type : 'text'}
                disabled={options.disabled}
            />
            {
                <div className="errors">
                    {options.errors && <small className="error-message">{options.errors.message}</small>}
                </div>
            }
        </div>
    )
}