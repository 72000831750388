import React from "react"
import "./auth-layout.scss"
import {Outlet} from 'react-router'
import {Logo} from "../../../assets/icons/logo";

export default function AuthLayout() {

    return(
        <div className="auth-section">
            <div className="container-fluid h-100">
                <div className="row h-100">
                    <div className="col-md-12 col-lg-6 p-0 h-100">
                        <div className="auth-slider">

                            {/*<div className={'box-shadow'}>*/}
                            {/*</div>*/}

                        </div>
                    </div>
                    <div className="col-md-6 p-0 h-100 d-md-none d-lg-block">
                        <div className="auth-form">
                            {/*<div className={'box-shadow'}></div>*/}
                            <div className={'auth-box'}>
                                <div className={'logo'}>
                                    <Logo/>
                                </div>

                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}