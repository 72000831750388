import React, {createContext, useContext, useEffect, useState} from 'react';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [firstLogin, setFirstLogin] = useState(false)
    // const [api, contextHolder] = notification.useNotification();
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark')
    const [sidebarCollapse, setSidebarCollapse] = useState(false)
    const [token, setToken] = useState('');
    const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {} );


    const checkTheme = () => {
        if(theme){
            document.body.setAttribute('data-theme', theme);
        }
    };

    const checkUser = () => {
        if(user){
            setUser(user)
        }
    }

    const sidebarSwitcher = () => {
        setSidebarCollapse(!sidebarCollapse)
    }


    const handleToken = (token) =>{
        setToken(token)
        localStorage.setItem("MusicToken", token)
    }
    const handleUserSave = (user) =>{
        setUser(user)
        localStorage.setItem("user", JSON.stringify(user))
    }
    const handleLogout = () =>{
        localStorage.removeItem("MusicToken")
        localStorage.removeItem("user")
        localStorage.removeItem("role")
        setToken('')
        setUser({})
    }


    useEffect(()=>{
        checkTheme()
    },[])

    useEffect(()=>{
        checkUser()
        console.log(user, 'check user id')
    },[user])

    const value={
        firstLogin,
        setFirstLogin,
        theme,
        user,
        setTheme,
        handleToken,
        handleLogout,
        handleUserSave,
        sidebarSwitcher,
        sidebarCollapse,
        setSidebarCollapse
    }

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};