import React, {useRef} from "react";
import "./contact.scss";
import {Col, Container, Row} from "react-bootstrap";
import mobileImage from "../../../../../assets/website-images/home/imager2.png";
import mobileImage1 from "../../../../../assets/website-images/home/Imager1.png";
import appBtn from "../../../../../assets/website-images/home/app-btn.png"
import gpBtn from "../../../../../assets/website-images/home/gp-btn.png"
import {motion, useInView} from "framer-motion";

export default function Contact() {
    const ref7 = useRef(null);
    const isInView7 = useInView(ref7, { once: true }); // Only trigger once
    return (
        <div className="contact" ref={ref7}>
            <Container>
                <Row>
                    <Col md={12} lg={6} xl={5} xxl={5}>
                        <motion.h2
                            className={'heading'}
                            initial={{ opacity: 0, y: 100 }}
                            animate={isInView7 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                            transition={{ duration: 1 }}
                        >
                            uTrust Touchless Multi-Modal Biometrics from Mobile
                        </motion.h2>
                        <motion.p
                            className='contact-para ml-0 ml-lg-5'
                            initial={{ opacity: 0, y: 100 }}
                            animate={isInView7 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                            transition={{ duration: 1 }}
                        >
                            Contact us to speak to an expert in the identity management field.
                        </motion.p>
                    </Col>
                    <Col xs={6} md={7} lg={6} xl={5} xxl={4} className={'d-none d-lg-block'}>
                        <div className="mobile-container">
                            <motion.img
                                alt=""
                                className="mobile-icon d-none d-xl-block"
                                initial={{ x: '-100vw', opacity: 0 }}
                                animate={isInView7 ? { x: 0, opacity: 1 } : { x: '-100vw', opacity: 0 }} // Slide in and fade in when in view
                                transition={{ duration: 1 }} // Duration of animation
                                src={mobileImage}/>
                            <motion.img
                                alt=""
                                className="mobile-icon d-block d-xl-none"
                                initial={{ x: '-100vw', opacity: 0 }}
                                animate={isInView7 ? { x: 0, opacity: 1 } : { x: '-100vw', opacity: 0 }} // Slide in and fade in when in view
                                transition={{ duration: 1 }} // Duration of animation
                                src={mobileImage1}/>
                        </div>
                    </Col>
                    <Col xs={6} md={7} lg={2} xl={2} xxl={2}>
                        <ul>
                            <li className={'mb-3'}>
                                <a href="#">
                                    <img src={appBtn} alt="" className='store-img'/>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <img src={gpBtn} alt="" className='store-img'/>
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
