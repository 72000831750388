export const EmailValidation = {
    value: new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    ),
    message: 'Invalid email format',
}
export const LinkValidation = {
    value: new RegExp(
        /^(https?:\/\/)?[\w.-]+\.[a-zA-Z]{2,4}/
    ),
    message: 'Invalid link',
}

export const PasswordValidation = {
    value: new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~^.#$@!%&*=?_])[A-Za-z\d`~^.#$@!%&*=?_]{8,30}$/
    ),
    message:
        'Password must contain atleast one uppercase letter, one lowercase letter, one number, one symbol & 8 characters.',
}
export const PhoneValidation = {
    value: new RegExp(/^(?!0+$)[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,9}$/im),
    message: 'Please enter valid phone Number.',
}
export const MacAddressValidation = {
    value: new RegExp(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/),
    message: 'Please enter valid mac address.',
}
export const PercentageValidation = {
    value: new RegExp(/^(100(?:\.0{1,2})?|0*?\.\d{1,2}|\d{1,2}(?:\.\d{1,2})?)$/),
    message: 'Not valid.',
}
export const checkEmptySpaces = (value) => {
    // Use a regular expression to check if the input contains only spaces
    if (/^\s+$/.test(value)) {
        return 'cannot contain only spaces';
    }
    return true; // Validation passed
};

export const alphaNumericValidation = {
    value: new RegExp(/^[A-Za-z]+$/),
    message: 'Only alphabets allowed',
}

export const alphaNumeric = (value) => {
    // Use a regular expression to check if the input contains only letters
    if (!/^[A-Za-z]+$/.test(value)) {
        return 'Only alphabets allowed';
    }
    return true; // Validation passed
};

export const minLength = (value) => {
    return {
        value,
        message: 'Min length is ' + value,
    }
}
export const maxLength = (value) => {
    return {
        value,
        message: 'Max length is ' + value,
    }
}
export const minValue = (value) => {
    return {
        value,
        message: 'Value must be greater than ' + value,
    }
}
export const MinLength = {
    value: 8,
    message: 'Min length is 8',
}

export const MinLengthTwo = {
    value: 2,
    message: 'Min length is 2',
}

export const MaxLength = {
    value: 255,
    message: 'Max length is 255',
}
export const Required = {
    value: true,
    message: 'This field is required',
}

export const required = (value) => {
    return {
        value,
        message: 'This field is required',
    }
}
