import { useState, useEffect, useRef } from 'react';

export function useDimensions() {
    const ref = useRef();
    const [dimensions, setDimensions] = useState({ height: 0 });

    useEffect(() => {
        const handleResize = () => {
            if (ref.current) {
                setDimensions({
                    height: ref.current.getBoundingClientRect().height
                });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return [ref, dimensions];
}