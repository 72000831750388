import React, {useRef, useState} from "react"
import productPageImg from "../../../../../assets/website-images/product/tochless/tochless-page-banner.png"
import productBannerImg from "../../../../../assets/website-images/product/product-banner.jpg";
import {motion, useInView} from "framer-motion";
import PageBanner from "../../../../components/website/pages-banner/PageBanner";
import {Col, Container, Row} from "react-bootstrap";
import "./tochless.scss"
import sec2b1 from "../../../../../assets/website-images/product/tochless/sec2-img-1.png"
import sec2b2 from "../../../../../assets/website-images/product/tochless/sec2-img-2.png"
import sec1Img from "../../../../../assets/website-images/product/tochless/sec-1.png";
import bio1 from "../../../../../assets/website-images/product/tochless/sec-3-1.png";
import bio2 from "../../../../../assets/website-images/product/tochless/sec-3-2.png";
import bio3 from "../../../../../assets/website-images/product/tochless/sec-3-3.png";
import bio4 from "../../../../../assets/website-images/product/tochless/sec-3-4.png";
import sec4box1 from "../../../../../assets/website-images/product/tochless/sec-4-1.png";
import sec4box2 from "../../../../../assets/website-images/product/tochless/sec-4-2.png";
import sec4box3 from "../../../../../assets/website-images/product/tochless/sec-4-3.png";
import sec4box4 from "../../../../../assets/website-images/product/tochless/sec-4-4.png";
import sec4box5 from "../../../../../assets/website-images/product/tochless/sec-4-5.png";
import sec4box6 from "../../../../../assets/website-images/product/tochless/sec-4-6.png";
import sec5box1 from "../../../../../assets/website-images/product/tochless/sec-5-1.png"
import sec5box2 from "../../../../../assets/website-images/product/tochless/sec-5-2.png"
import sec5box3 from "../../../../../assets/website-images/product/tochless/sec-5-3.png"
import sec6box1 from "../../../../../assets/website-images/product/tochless/sec-6-1.png"
import sec6box2 from "../../../../../assets/website-images/product/tochless/sec-6-2.png"
import sec6box3 from "../../../../../assets/website-images/product/tochless/sec-6-3.png"
import sec6box4 from "../../../../../assets/website-images/product/tochless/sec-6-4.png"
import sec7 from "../../../../../assets/website-images/product/tochless/sec-7-1.png"

export default function TochlessPage() {
    const ref2 = useRef(null);
    const ref5 = useRef(null);
    const isInView2 = useInView(ref2, { once: true });
    const isInView5 = useInView(ref5, { once: true });
    const [activeSection, setActiveSection] = useState(1);
    const containerVariants2 = {
        hidden: { opacity: 1 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Staggering the animation of each child by 0.3 seconds
            },
        },
    };
    const cardVariants = {
        hidden: { opacity: 0, x: -100 }, // Start with opacity 0 and 100px to the left
        show: { opacity: 1, x: 0 },      // Fade in and move to original position
    };


    const handleSectionClick = (sectionIndex) => {
        setActiveSection(sectionIndex);
    };

    return(
        <div className={'tochless'}>
            <PageBanner img={productPageImg} heading={'uTrust Touchless Multimodal Biometrics from Mobile'} bannerImg={productBannerImg}/>
            <div className={'section-1'}>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <h3 className={''}>Introducing uTrust Touchless Multimodal Biometrics from Mobile</h3>
                            <p>Revolutionize Your Security with Advanced Mobile Biometric Authentication
                                Discover the future of identity verification with uTrust Touchless Multi-Modal Biometrics from Mobile. This cutting-edge solution captures and authenticates biometric data using mobile devices, offering unparalleled convenience, security, and accuracy for a wide range of applications.</p>
                        </Col>
                        <Col lg={6} className={'text-center'}>
                            <img src={sec1Img} className={'img-fluid ms-auto'}/>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-2'}>
                <Container>
                    <Row>
                        <Col lg={7}>
                            <h2 className={'heading'}>Why Choose uTrust Touchless Multi-Modal Biometrics?</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <div className={'sec2-box'}>
                                <div className={'img'}>
                                    <img src={sec2b1}/>
                                </div>
                                <div className={'content'}>
                                    <h4>Touchless Convenience</h4>
                                    <p>Experience the ease of quick and effortless identity authentication without physical contact. The mobile technology ensures that biometric authentication is accessible to everyone, enhancing user experience and operational efficiency.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className={'text-center'}>
                            <div className={'sec2-box'}>
                                <div className={'img'}>
                                    <img src={sec2b2}/>
                                </div>
                                <div className={'content'}>
                                    <h4>Multi-Modal Biometric Capabilities</h4>
                                    <p>Our solution integrates advanced facial recognition and touchless fingerprint scanning. With sophisticated algorithms, it captures and verifies biometric data with high accuracy, providing robust protection against unauthorized access and fraudulent activities.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="home-featured-products">
                <Container>
                    <Row>
                        <Col>
                            <div ref={ref2}>
                                <motion.h2
                                    className={'heading'}
                                    initial={{ opacity: 0, y: 100 }}
                                    animate={isInView2 ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                                    transition={{ duration: 1 }}
                                >
                                    Advanced Biometric Features
                                </motion.h2>
                            </div>

                            <div className="cards" ref={ref5}>
                                <Row>
                                    <Col md={6}>
                                        <motion.div
                                            className="product-card"
                                            initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                            animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                            transition={{ duration: 1.4, delay: 1 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio1}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={7}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>High-Quality Capturing and Template Creation</h4>
                                                        <p>
                                                            Utilizing advanced sensors and algorithms, uTrust captures high-quality facial and fingerprint images swiftly. Each biometric is processed to create detailed templates, ensuring accurate representation.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                    <Col md={6}>
                                        <motion.div
                                            className="product-card"
                                            initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                            animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                            transition={{ duration: 1.4, delay: 2 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio2}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={7}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>Liveness Detection for Enhanced Security</h4>
                                                        <p>
                                                            Our system confirms that biometric data is from a live person, preventing fraudulent attempts with fake or spoofed sources.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                    <Col md={6}>
                                        <motion.div
                                            className="product-card"
                                            initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                            animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                            transition={{ duration: 1.4, delay: 3 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio3}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={7}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>Exceptional Accuracy and Speed</h4>
                                                        <p>
                                                            State-of-the-art technology ensures high accuracy in both facial and fingerprint recognition. Optimized processing speeds provide quick and seamless authentication, perfect for high-volume applications.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                    <Col md={6}>
                                        <motion.div style={{display: "flex"}}
                                                    className="product-card"
                                                    initial={{ opacity: 0, y: 50 }} // Start with opacity 0 and move up
                                                    animate={isInView5 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Fade in and move to original position
                                                    transition={{ duration: 1.4, delay: 4 * 0.2 }} // Staggered effect
                                        >
                                            <Row>
                                                <Col lg={5} xl={4}>
                                                    <img
                                                        src={bio4}
                                                        alt=""
                                                        className="card-img"
                                                    />
                                                </Col>
                                                <Col lg={7} xl={7}>
                                                    <div className="card-info mt-3 mt-lg-0">
                                                        <h4>Efficient Duplicate Detection </h4>
                                                        <p>
                                                            Maintain the integrity and uniqueness of each registered biometric with our efficient duplicate detection. This process is crucial for accurate and reliable biometric systems.                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </motion.div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-4'}>
                <Container>
                    <Row>
                        <h2 className={'heading'}>Facial Biometrics: Cutting-Edge Technology</h2>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec4box1}/>
                                <h4>Face Detection and Recognition </h4>
                                <p>Capture detailed facial images with high-resolution cameras and sophisticated software. Accurate detection in various conditions ensures reliable recognition, even in challenging environments.</p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec4box2}/>
                                <h4>Accessory and Background Detection </h4>
                                <p>Our system detects accessories like glasses and masks, ensuring accurate recognition. Background uniformity assessment improves facial capture quality and reduces errors.</p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec4box3}/>
                                <h4>Comprehensive Facial Feature Extraction </h4>
                                <p>Extract key facial features, such as eye distance, nose shape, and jawline, to create a comprehensive biometric template. This ensures high accuracy in facial recognition.</p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec4box4}/>
                                <h4>Real-Time Eye and Mouth Detection </h4>
                                <p>Detect whether eyes and mouth are open or closed, enhancing liveness detection and overall facial recognition performance.</p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec4box5}/>
                                <h4>Head Movement Detection </h4>
                                <p>Accurately detect head movements to ensure consistent recognition, regardless of the angle.</p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className={'d-box'}>
                                <img src={sec4box6}/>
                                <h4>Quick and Reliable Facial Matching </h4>
                                <p>Compare captured facial features against stored databases for instant and accurate identity verification.</p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-5'}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <h2 className={'heading'}>Unmatched Security and Compliance</h2>
                        </Col>
                        <Col lg={12}>
                            <ul>
                                <li>
                                    <div className={'sec5-box'}>
                                        <img src={sec5box1}/>
                                        <div className={'content'}>
                                            <h4>Secure Encryption</h4>
                                            <p>Biometric data is securely encrypted both at rest and in transit, adhering to international security and privacy standards. Our rigorous security framework ensures the safe handling of sensitive information.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className={'sec5-box'}>
                                        <img src={sec5box2}/>
                                        <div className={'content'}>
                                            <h4>Cost-Effective and Scalable Solution </h4>
                                            <p>Leveraging mobile technology, uTrust reduces the need for expensive hardware, making it an affordable and accessible solution. Our system scales effortlessly to meet growing needs and manage increasing data volumes efficiently.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className={'sec5-box'}>
                                        <img src={sec5box3}/>
                                        <div className={'content'}>
                                            <h4>Easy Integration Across Sectors </h4>
                                            <p>Designed for versatility, uTrust integrates seamlessly into existing systems across various sectors, including government, finance, healthcare, and corporate environments. Its compatibility with a wide range of mobile devices ensures broad accessibility and easy deployment.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'section-6'}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className={'heading-section'}>
                                <h2 className={'heading'}>Unmatched Security and Compliance</h2>
                                <h4 className={'subheading'}>uTrust Touchless Multi-Modal Biometrics is ideal for:</h4>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} xl={3}>
                            <div className={'sec6-box'}>
                                <img src={sec6box1}/>
                                <h4>Government Services</h4>
                                <p>Securely verify citizen identities for public services and efficient onboarding processes.</p>
                            </div>
                        </Col>
                        <Col lg={6} xl={3}>
                            <div className={'sec6-box'}>
                                <img src={sec6box2}/>
                                <h4>Border Security and Immigration</h4>
                                <p>Facilitate fast and secure identity verification for cross-border movements.</p>
                            </div>
                        </Col>
                        <Col lg={6} xl={3}>
                            <div className={'sec6-box'}>
                                <img src={sec6box3}/>
                                <h4>Criminal Departments and Law Enforcement</h4>
                                <p>Enhance suspect identification and criminal record management.</p>
                            </div>
                        </Col>
                        <Col lg={6} xl={3}>
                            <div className={'sec6-box'}>
                                <img src={sec6box4}/>
                                <h4>Banking Sector</h4>
                                <p>Improve security for online banking and mobile payments, streamlining customer enrollment and verification.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={'product-section'}>
                <Container>
                    <Row className={'flex-column-reverse flex-lg-row'}>
                        <Col lg={5} className={'text-center'}>
                            <img src={sec7} className={'img-fluid'}/>
                        </Col>
                        <Col lg={7} className={'d-flex align-items-center'}>
                            <div>
                                <p>Explore the future of identity management with uTrust Touchless Multi-Modal Biometrics. Our secure, efficient, and user-friendly solution ensures that your organization meets the demands of a digital world with confidence and ease.</p>
                                <h5 className={'subheading'}>Contact Us today to learn more and schedule a demo!</h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}