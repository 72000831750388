import React, {useState} from "react"
import {Controller, useForm} from "react-hook-form";
import {Form} from 'react-bootstrap'
import {EmailValidation, maxLength, PasswordValidation, Required} from "../../../utils/patterns";
import TextInput from "../../../components/common/input-field/TextInput";
import "./login.scss"
import ThemeButton from "../../../components/common/theme-button/ThemeButton";

import PasswordInput from "../../../components/common/password-field/PasswordInput";
import {useNavigate} from "react-router";
import ThemeModal from "../../../components/common/modals/theme-modal/ThemeModal";
import PrivacyPolicy from "../policy/PrivacyPolicy";
import {notification} from "antd";
import {RoundedCheck} from "../../../../assets/icons/defaultIcons";

import {useUser} from "../../../providers/UserContext";


const users = [
    {
        id: 1,
        name: 'John Doe',
        email: 'user@ruk.com',
        password: 'Demo@123',
        roleID:3,
        token: 'artist',
    },
    {
        id: 2,
        name: 'Fan User',
        email: 'fan@musichabitat.com',
        password: 'Demo@123',
        roleID:2,
        token: 'fan'
    },
    {
        id: 3,
        name: 'Admin User',
        email: 'venue@musichabitat.com',
        password: 'Demo@123',
        roleID:4,
        token: 'venue'
    },
];

export default function Login() {
    const [privacyModal, setPrivacyModal] = useState(false);
    const [termsModal, setTermsModal] = useState(false);
    const [loader, setLoader] = useState(false)
    const { handleToken, handleUserSave, firstLogin} = useUser()
    console.log("login")
    const {
        handleSubmit,
        formState: {errors},
        control,
    } = useForm({
        mode: 'onChange',
    })

    const navigate = useNavigate()


    const onSubmit = async (data) => {
        console.log(data)
        const { email, password } = data;
        setLoader(true)
        const user = users.find((u) => u.email === email && u.password === password);
        if (user) {
            console.log(user, 'full user')
            handleUserSave(user)

            setTimeout(()=>{
                notification.success({
                    message: "Success",
                    description: "Login successfully!",
                    placement: "topRight",
                    icon: <RoundedCheck/>,
                    duration: 2,
                });
                setLoader(false)
                handleToken(user.token)

                // if (firstLogin){
                //     navigate("/role-selection")
                // }
                // else{
                //     navigate("/")
                // }

            },2000)
        }
        else {
            setLoader(true)
            setTimeout(()=>{
                notification.error({
                    message: "Failed",
                    description: "Login failed. Please check your credentials !",
                    placement: "topRight",
                    icon: <RoundedCheck/>,
                    duration: 2,
                });
                setLoader(false)
            },2000)
        }
    }

    return(
        <div className={'login'}>
            <h3>Sign In</h3>
            <h5>Let’s get you into the app! </h5>
            <div className={'box-shadow'}></div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={'dfields'}>
                    <Controller
                        name="email"
                        defaultValue={''}
                        control={control}
                        rules={{required: Required, pattern:EmailValidation, maxLength: maxLength(100)}}
                        render={({field: {name, ...field}}) => (
                            <TextInput
                                placeholder={'Enter your email'}
                                label={'Email Address'}
                                type={'email'}
                                field={field}
                                errors={errors.email}
                                className={'required'}
                            />
                        )}
                    />
                </div>
                <div className={'dfields'}>
                    <Controller
                        name="password"
                        defaultValue={''}
                        control={control}
                        rules={{required: Required, pattern:PasswordValidation, maxLength: maxLength(100)}}
                        render={({field: {name, ...field}}) => (
                            <PasswordInput
                                placeholder={'Enter your password'}
                                label={'Password'}
                                field={field}
                                errors={errors.password}
                                className={'required'}
                            />
                        )}
                    />
                </div>
                <p className={'forget-pass'} onClick={()=>{navigate("/forgot-password")}}>Forgot Password?</p>
                <div className={'dfields'}>
                    <ThemeButton
                        text={'Sign in'}
                        className={'btn-light-lg'}
                        type={'submit'}
                        loader={loader}
                    />
                </div>
                {/*<div>*/}
                {/*    <p onClick={()=>{navigate('/signup')}} className={'other-option mt-4'}>Don’t have an account? <span>Sign Up</span></p>*/}
                {/*</div>*/}
                <ThemeModal
                    modalHeading={'Terms & Conditions'}
                    show={termsModal}
                    close={setTermsModal}
                    children={<PrivacyPolicy/>}
                    headingSize={24}
                    className={'with-shadow'}
                />
                <ThemeModal
                    modalHeading={'Privacy Policy'}
                    show={privacyModal}
                    close={setPrivacyModal}
                    children={<PrivacyPolicy/>}
                    headingSize={24}
                    className={'with-shadow'}
                />
            </Form>
        </div>
    )
}